/* global Office process NodeJS console setTimeout clearTimeout */
import { generateDialogMessageForError } from "./misc";

// Define the type for the dialog options
interface ExcelDialogOptions {
  dialogUrl?: string;
  height?: number;
  width?: number;
  messageHandler?: (arg: Office.DialogParentMessageReceivedEventArgs) => void;
  dialogMessage: ReturnType<typeof generateDialogMessageForError>;
}

const TIME_TO_WAIT_FOR_DIALOG = 5000;

// Utility function to show an Excel dialog
export function showExcelDialog(options: ExcelDialogOptions): Promise<void> {
  return new Promise((resolve, reject) => {
    const { dialogUrl = `${process.env.FRONT_END_URL}/alert-modal.html`, height = 30, width = 20 } = options;

    Office.onReady((info: { host: Office.HostType; platform: Office.PlatformType }) => {
      if (info.host === Office.HostType.Excel) {
        let timeoutId: NodeJS.Timeout;

        const timeoutPromise = new Promise((_, timeoutReject) => {
          timeoutId = setTimeout(() => {
            timeoutReject(new Error("displayDialogAsync timed out after 5 seconds"));
          }, TIME_TO_WAIT_FOR_DIALOG);
        });

        const displayDialogPromise = new Promise<void>((resolveDisplay, rejectDisplay) => {
          Office.context.ui.displayDialogAsync(dialogUrl, { height, width }, (asyncResult) => {
            clearTimeout(timeoutId);
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              try {
                const dialog = asyncResult.value;

                // Register an event handler to process messages received from the dialog
                dialog.addEventHandler(
                  Office.EventType.DialogMessageReceived,
                  (arg: Office.DialogParentMessageReceivedEventArgs) => {
                    const message = JSON.parse(arg.message);
                    if (message.status === "ready") {
                      dialog.messageChild(JSON.stringify(options.dialogMessage), { targetOrigin: "*" });
                      resolveDisplay();
                    }
                  }
                );
              } catch (error) {
                console.error("Error sending message to dialog: " + error);
                rejectDisplay(new Error("Error sending message to dialog: " + error));
              }
            } else {
              console.error("Failed to open dialog: " + asyncResult.error.message);
              rejectDisplay(new Error("Failed to open dialog: " + asyncResult.error.message));
            }
          });
        });

        Promise.race([timeoutPromise, displayDialogPromise]).then(resolve).catch(reject);
      } else {
        reject(new Error("Office host is not Excel"));
      }
    });
  });
}
