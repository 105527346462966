/* global */

import * as React from "react";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import { GenerateEquation } from "@/taskpane/components/GenerateEquation";
import { insertText } from "@/taskpane/taskpane";
import { Home } from "@/taskpane/components/Home";
import { AIChat } from "@/taskpane/components/AIChat";
import { Lighthouse } from "@/taskpane/components/Lighthouse";
import { Windows } from "@/taskpane/components/Windows";

import {
  AI_CHAT_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  EXCEL_FROM_SOURCES_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  GENERATE_EQUATION_ROUTE,
  GENERATE_GRAPH_ROUTE,
  HOME_ROUTE,
  LOGIN_SIGNUP_ROUTE,
  USER_CHANGE_PASSWORD_ROUTE,
  VERIFY_EMAIL_ROUTE,
  VIEW_PLANS_ROUTE,
} from "@/constants/frontendRoutes";
import Login from "@/taskpane/components/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { useTheme } from "@/providers/ThemeProvider";
import { Graph } from "@/taskpane/components/Graph";
import { ExcelFromSources } from "@/taskpane/components/ExcelFromSources";
import { EmailVerification } from "@/taskpane/components/EmailVerification";
import { ForgotPasswordView } from "@/taskpane/components/ForgotPasswordView";
import { ChangePasswordView } from "@/taskpane/components/ChangePasswordView";
import { UserChangePasswordView } from "@/taskpane/components/UserChangePassword";
import { Plans } from "@/taskpane/components/Plans";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { theme } = useTheme();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div
          className="min-h-screen bg-[linear-gradient(176.47deg,_#25EAAF_-12.1%,_#17B2E2_43.7%,_rgba(15,110,197,0.8)_109.59%)]"
          style={{ fontFamily: "Switzer, sans-serif" }}
        >
          <Toaster
            position="top-right"
            toastOptions={{
              style: {
                fontSize: theme.fontSize.small,
              },
            }}
          />
          <Routes>
            <Route path={LOGIN_SIGNUP_ROUTE} element={<Login />} />
            <Route path={VERIFY_EMAIL_ROUTE} element={<EmailVerification />} />
            <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordView />} />
            <Route path={CHANGE_PASSWORD_ROUTE} element={<ChangePasswordView />} />
            <Route path={USER_CHANGE_PASSWORD_ROUTE} element={<UserChangePasswordView />} />
            <Route path={VIEW_PLANS_ROUTE} element={<Plans />} />
            <Route path={GENERATE_EQUATION_ROUTE} element={<GenerateEquation insertText={insertText} />} />
            <Route path={HOME_ROUTE} element={<Home />} />
            <Route path={AI_CHAT_ROUTE} element={<AIChat />} />
            <Route path={GENERATE_GRAPH_ROUTE} element={<Graph />} />
            <Route path={EXCEL_FROM_SOURCES_ROUTE} element={<ExcelFromSources />} />
            <Route path="/lighthouse" element={<Lighthouse />} />
            <Route path="/windows" element={<Windows />} />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
