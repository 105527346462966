import React, { useState, useEffect } from "react";
import ExcelFileIcon from "@/assets/ExcelFileIcon.svg";
import { Image } from "@fluentui/react-components";

const loadingMessages = [
  "Diving deep into the data ocean...",
  "Shark-sorting your information...",
  "Swimming through spreadsheets...",
];

const Step2Processing = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="text-center flex flex-col items-center h-full justify-center my-auto">
      <Image src={ExcelFileIcon} alt="Excel File Icon" className="animate-bounce-spin" />
      <h2 className="text-xl font-bold mb-2">Processing your data</h2>
      <p className="text-xs">{loadingMessages[messageIndex]}</p>
    </div>
  );
};

export default Step2Processing;
