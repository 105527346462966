import { useQuery } from "@tanstack/react-query";
import { apiGet } from "@/utils/fetch";

interface AuthSessionResponse {
  ok: boolean;
  message: string;
}

// Create the useAuthSession hook to check the session status
export const useAuthSession = () => {
  return useQuery<AuthSessionResponse, Error>({
    queryKey: ["authSession"],
    queryFn: async () => {
      const response = await apiGet<AuthSessionResponse>("/api/auth/session");
      return response;
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
