import { useQuery } from "@tanstack/react-query";
import { apiGet } from "@/utils/fetch";
import { CHAT_AI_ENDPOINT } from "@/constants";
import { useFetchWithAuth } from "./useFetchWithAuth";

interface Conversation {
  id: number;
  title: string;
}

export const useFetchConversations = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<Conversation[], Error>({
    queryKey: ["conversations"],
    queryFn: async () => {
      return fetchWithAuth(async () => {
        const response = await apiGet<Conversation[]>(CHAT_AI_ENDPOINT);
        return response as Conversation[];
      });
    },
  });
};
