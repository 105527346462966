/* global TextDecoder */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LLM_MODELS } from "@/constants";
import { apiPostStreaming } from "@/utils/fetch";
import { Message } from "./useFetchConversationMessages";

interface GenerateConversationInput {
  message: string;
  model: string;
  aiMessage: string;
}

export const useGenerateConversation = (
  onMessage: (message: string) => void,
  onStream: (isStreaming: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation<{ newContent: string; conversationId: number }, Error, GenerateConversationInput>({
    mutationFn: async ({ message, aiMessage }) => {
      const url = `/api/conversations/stream`;
      const response = await apiPostStreaming(url, {
        message: message,
        model: LLM_MODELS.DEFAULT,
        aiMessage: aiMessage,
      });

      const reader = response.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;
      let newContent = "";
      let buffer = "";
      let conversationId: number | null = null;

      while (!done) {
        const { value, done: isDone } = await reader.read();
        onStream(true);
        done = isDone;

        const chunk = decoder.decode(value || new Uint8Array(), { stream: true });
        buffer += chunk;

        if (!conversationId) {
          const parts = chunk.split(",");
          for (const part of parts) {
            if (part.includes('"id"')) {
              conversationId = parseInt(part.split(":")[1].replace(/[^0-9]/g, ""));
              break;
            }
          }
        }

        const contentMatches = buffer.match(/"content":\s*"([^"]*)"/g);
        if (contentMatches) {
          contentMatches.forEach((match) => {
            const content = match.replace(/"content":\s*"/, "").replace(/"$/, "");
            newContent += content;
            onMessage(newContent);
          });
          buffer = "";
        }
      }

      onStream(false);
      return { newContent, conversationId };
    },
    onSuccess: ({ newContent, conversationId }, { message, aiMessage }) => {
      if (conversationId) {
        queryClient.setQueryData<Message[]>(["conversationMessages", conversationId], (oldMessages = []) => [
          ...oldMessages,
          // Append the AI's initial system message
          { id: Date.now() + 1, content: aiMessage, role: "AI", conversationId },
          // Append the user's message
          { id: Date.now(), content: message, role: "HUMAN", conversationId },
          // Append the AI's response from the LLM
          { id: Date.now() + 2, content: newContent, role: "AI", conversationId },
        ]);
      }

      // Invalidate other queries if needed
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
    },
  });
};
