/* global Excel Office */

import React, { useEffect, useState } from "react";
import { MainLayout } from "../layouts/MainLayout";
import {
  insertChart,
  getDefaultRange,
  getSelectedValues,
  getSelectedRangeAddress,
  checkRangeValues,
} from "../taskpane";
import { Button, Dialog, Tooltip } from "@fluentui/react-components";
import { GlassBackground } from "./GlassBackground";

import LoadingCircle from "./LoadingCircle";
import toast from "react-hot-toast";
import { useGenerateGraph } from "@/hooks/useGenerateGraph";
import { CustomDialogSurface } from "./CustomDialogSurface";
import { RangeStatus } from "@/types/graph";
import { PiInfoThin } from "react-icons/pi";

export const Graph = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [userPrompt, setUserPrompt] = useState<string>("");
  const [reason, setReason] = useState<React.ReactNode | null>(null);
  const [rangeStatus, setRangeStatus] = useState<string>(RangeStatus.NoValues);
  const [ranges, setRanges] = useState<string[]>([]);
  const [hasValues, setHasValues] = useState<boolean>(false);
  const [insertedChart, setInsertedChart] = useState<Excel.Chart | null>(null);

  const { mutate: generateGraph, isPending: isCreatingChart } = useGenerateGraph();

  console.log(rangeStatus);

  useEffect(() => {
    Office.onReady(() => {
      Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getActiveWorksheet();

        // Attach the selection change handler
        sheet.onSelectionChanged.add(handleSelectionChange);

        await context.sync();
      });
    });

    return () => {
      Excel.run(async (context) => {
        try {
          const sheet = context.workbook.worksheets.getActiveWorksheet();
          if (sheet) {
            sheet.onSelectionChanged.remove(handleSelectionChange);
          }
          await context.sync();
        } catch (error) {
          console.error("Error cleaning up event handler:", error);
        }
      });
    };
  }, []);

  // Function to handle selection change
  const handleSelectionChange = async (): Promise<void> => {
    try {
      const rangeAddress = await getSelectedRangeAddress();
      if (rangeAddress) {
        setRanges(rangeAddress);

        const hasValuesInRange = await checkRangeValues(rangeAddress);
        setHasValues(hasValuesInRange);
        setRangeStatus(hasValuesInRange ? RangeStatus.ValuesAvailable : RangeStatus.NoValues);
      } else {
        setHasValues(false);
        setRangeStatus(RangeStatus.NoValues);
      }
    } catch (error) {
      console.error("Error handling selection change:", error);
    }
  };

  const removeChart = async () => {
    if (insertedChart) {
      try {
        await Excel.run(async (context) => {
          // Get the active worksheet
          const sheet = context.workbook.worksheets.getActiveWorksheet();
          if (!sheet) {
            throw new Error("No active worksheet found.");
          }

          let chart;

          try {
            chart = sheet.charts.getItem(insertedChart.name);
          } catch (error) {
            console.warn("Chart name not available, attempting by ID.");
            // Fall back to fetching the chart by ID
            chart = sheet.charts.getItem(insertedChart.id);
          }

          // Delete the chart
          chart.delete();

          await context.sync();
          console.log("Chart removed successfully.");

          // Reset the state
          setInsertedChart(null);
        });
      } catch (error) {
        console.error("Error removing chart:", error);
      }
    }
  };

  const handleAcceptDialog = () => {
    console.log("Accepted");
    setIsDialogOpen(false);
  };

  const handleCloseDialog = async () => {
    await removeChart(); // Remove the chart on dialog close
    setIsDialogOpen(false);
  };

  const handleGenerateChart = async () => {
    try {
      if (!userPrompt.trim()) {
        toast.error("Prompt is required to generate a chart.");
        return;
      }

      let currentRange: string[] = [];

      if (!hasValues) {
        // No values selected, fallback to provided ranges
        const defaultRange = await getDefaultRange();

        if (!defaultRange) {
          toast.error("No default range available.");
          return;
        }

        // Extract ranges without sheet names
        const rangePart = defaultRange.split("!").pop() || ""; // e.g., "Sheet1!A1:C3,B1:B4" -> "A1:C3,B1:B4"
        currentRange = rangePart.split(","); // Split into an array of ranges
        setRangeStatus(RangeStatus.ValuesAvailable);

        // Fetch values for the fallback ranges
        const values = await getSelectedValues(currentRange);

        if (!values || values.length === 0) {
          toast.error("No data available to generate a chart.");
          return;
        }

        // Proceed with the generateGraph mutation for fallback ranges
        generateGraph(
          { selectedValues: values, range: currentRange, userPrompt, hasHeaders: true },
          {
            onSuccess: async (response) => handleGraphSuccess(response),
            onError: (error) => handleGraphError(error),
          }
        );
      } else {
        // Use selected ranges directly
        const values = await getSelectedValues(); // No params to use the selected ranges

        if (!values || values.length === 0) {
          toast.error("No data available to generate a chart.");
          return;
        }

        // Proceed with the generateGraph mutation for selected ranges
        generateGraph(
          { selectedValues: values, range: ranges, userPrompt, hasHeaders: true },
          {
            onSuccess: async (response) => handleGraphSuccess(response),
            onError: (error) => handleGraphError(error),
          }
        );
      }
    } catch (error) {
      console.error("Unexpected error in generate chart:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  const handleGraphSuccess = async (response) => {
    if (response.chart.type === "Invalid") {
      toast.error("Invalid data. Failed to generate a chart.");
      return;
    }

    try {
      if (response && response.chart) {
        setIsDialogOpen(true);
        const chart = await insertChart(response.chart);
        setInsertedChart(chart);
        setReason(
          <>
            <span>Chart Type: {response.chart.type}</span>
            <br />
            <br />
            {response.chart.reason}
          </>
        );
        toast.success("Chart generated successfully!");
      } else {
        throw new Error("Invalid response from generateGraph.");
      }
    } catch (error) {
      console.error("Error inserting chart:", error);
      toast.error("Error inserting chart.");
    }
  };

  const handleGraphError = (error) => {
    console.error("Error generating graph:", error);
    toast.error("Failed to generate chart.");
  };

  return (
    <MainLayout headerTitle="Graph">
      <GlassBackground>
        <div className="flex flex-col h-full w-full gap-4">
          <h2 className="text-lg font-bold text-white text-center">Insert Chart</h2>

          <div className="flex flex-col gap-1">
            <div className="flex gap-1">
              <span className="font-bold text-sm">Selected Range:</span>
              <div className="flex flex-wrap gap-1">
                {ranges.length === 0 ? (
                  <div className="rounded-full text-xs p-1 bg-teal-gradient">No range selected</div>
                ) : (
                  ranges.map((range, index) => (
                    <div key={index} className="rounded-full text-xs p-1 bg-teal-gradient">
                      {range}
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="flex w-full items-center justify-between p-1 rounded-lg text-white border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] outline-none">
              <span className="block text-xs text-center">{rangeStatus}</span>
              <Tooltip
                positioning="above"
                relationship="label"
                content={
                  <CustomTooltip>
                    If no values are found, the tool defaults to all the data in the current worksheet.
                  </CustomTooltip>
                }
              >
                <div className="relative">
                  <PiInfoThin className="cursor-pointer w-5 h-5" />
                </div>
              </Tooltip>
            </div>
          </div>

          <textarea
            placeholder="Enter your prompt here..."
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}
            className="inputField rounded-lg p-2.5 text-white text-sm shadow-md border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] outline-none resize-y overflow-auto"
            // size="large"
            rows={6}
          />

          <Button
            onClick={handleGenerateChart}
            appearance="primary"
            disabled={isCreatingChart}
            size="large"
            type="submit"
            style={{
              padding: "0.6rem 2rem",
              borderRadius: "24px",
              background: "#080808",
              fontSize: "12px",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isCreatingChart ? <LoadingCircle width="16px" height="16px" /> : "Generate Chart"}
          </Button>
        </div>
      </GlassBackground>

      <Dialog open={isDialogOpen} onOpenChange={(event, data) => setIsDialogOpen(data.open)}>
        <CustomDialogSurface
          variant="decision"
          onClose={handleCloseDialog}
          onAction={handleAcceptDialog}
          content={reason}
          contentStyles={{
            fontSize: "12px",
          }}
        />
      </Dialog>
    </MainLayout>
  );
};

const CustomTooltip = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={`py-1 px-2 rounded-lg text-white text-sm ${className}`}
      style={{ background: "linear-gradient(to right, #38ccd5 0%, #36c7d9 80%)" }}
    >
      {children}
    </div>
  );
};
