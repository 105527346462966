import { VERIFY_EMAIL_ROUTE } from "@/constants/frontendRoutes";
import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

interface UserInfoResponse {
  id: number;
  email: string;
  verificationStatus: string;
  verifiedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
interface SignUpInput {
  email: string;
  password: string;
}
interface SignUpResponse {
  ok: boolean;
  userInfo: UserInfoResponse;
}

// Function to sign up a user with email
async function signupWithEmail(email: string, password: string): Promise<SignUpResponse> {
  return apiPost<SignUpResponse>("/api/auth/signup-with-email", { email, password });
}

export const useSignUp = () => {
  const navigate = useNavigate();
  return useMutation<SignUpResponse, Error, SignUpInput>({
    mutationFn: ({ email, password }) => signupWithEmail(email, password),
    onSuccess: async () => {
      navigate(VERIFY_EMAIL_ROUTE);
    },
  });
};
