import { useTheme } from "@/providers/ThemeProvider";
import * as React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";

interface Message {
  id: number;
  content: string;
  role: string; // HUMAN or AI, SYSTEM
  conversationId: number;
  failed?: boolean;
}

const RenderChatBubble = ({ message }: { message: Message }) => {
  const { theme } = useTheme();
  const isUserMessage = message.role === "HUMAN";

  return (
    <div className={`flex ${isUserMessage ? "justify-end" : "justify-start"} z-10`}>
      <div
        className="rounded-md p-2.5 mb-2.5 text-white max-w-[60%] break-words"
        style={{
          background: isUserMessage
            ? "#00002D"
            : "linear-gradient(180.48deg, rgba(255, 255, 255, 0.15) -38.36%, rgba(255, 255, 255, 0.15) 168.61%)",
          boxShadow: isUserMessage ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "0px 4px 4px rgba(0, 0, 0, 0.25)",
          backdropFilter: isUserMessage ? "none" : "blur(10px)",
        }}
      >
        <span style={{ fontSize: theme.fontSize.medium }}>{message.content}</span>
      </div>
      {message.failed && (
        <div className="flex items-center mb-[2px]">
          <IoMdInformationCircleOutline fill={theme.error} size={10} />
          <span className="text-red-500 text-[8px]">Failed to send</span>
        </div>
      )}
    </div>
  );
};

const MemoizedRenderChatBubble = React.memo(RenderChatBubble);
MemoizedRenderChatBubble.displayName = "RenderChatBubble";

export default MemoizedRenderChatBubble;
