import { Button } from "@fluentui/react-components";
import React, { useState } from "react";
import { GlassBackground } from "../GlassBackground";

interface Step3SheetSelectionProps {
  onSelect: (selectedSheetName: string) => void;
  sheets: string[];
}

const Step3SheetSelection = ({ onSelect, sheets }: Step3SheetSelectionProps) => {
  const [selectedSheet, setSelectedSheet] = useState("");
  console.log(sheets);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedSheet) onSelect(selectedSheet);
  };

  return (
    <div className="flex h-full w-full my-auto">
      <GlassBackground>
        <form onSubmit={handleSubmit} className="space-y-4 h-full my-auto">
          <div className="flex flex-col gap-1">
            <h2 className="text-xl font-bold">Sheet Selection</h2>
            <p className="mb-4 text-white/80 text-sm">
              We&apos;ve discovered multiple sheets in your ocean of data. Which one should we dive into?
            </p>
          </div>
          {!sheets.length ? (
            <p>No sheets available to select.</p>
          ) : (
            <select
              className="w-full p-1 border border-gray-300 bg-[rgba(217,217,217,0.5)] rounded-md"
              value={selectedSheet}
              onChange={(e) => setSelectedSheet(e.target.value)}
            >
              <option value="">Select a sheet</option>
              {sheets.map((sheet) => (
                <option key={sheet} value={sheet}>
                  {sheet}
                </option>
              ))}
            </select>
          )}

          <Button
            appearance="primary"
            disabled={!selectedSheet}
            size="large"
            type="submit"
            style={{
              width: "100%",
              padding: "0.6rem 2rem",
              borderRadius: "24px",
              background: "#080808",
              fontSize: "12px",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Proceed
          </Button>
        </form>
      </GlassBackground>
    </div>
  );
};

export default Step3SheetSelection;
