/* global process */

export function getEnvVariable(name: string): string {
  // prod is being werid and I just want to hack around it
  if (typeof process === "undefined" || typeof process.env === "undefined") {
    switch (name) {
      case "REACT_APP_API_BASE_URL":
        // staging: return "https://sheet-shark-backend-staging.fly.dev
        return "https://api.sheetshark.com";
      case "FRONT_END_URL":
        return "https://app.sheetshark.com";
      case "REACT_APP_USE_PROXY":
        return "false"; // Not needed
      default:
        break;
    }
  }
  const value = process.env[name];
  return value;
}
