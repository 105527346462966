import { useQuery } from "@tanstack/react-query";
import { apiGet } from "@/utils/fetch";
import { useFetchWithAuth } from "./useFetchWithAuth"; // Import your custom hook

export interface GeneratedEquation {
  equations_input: string;
  equations_response: string;
}

export const useFetchGeneratedEquation = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<GeneratedEquation, Error>({
    queryKey: ["generatedEquation"],
    queryFn: async () => {
      return fetchWithAuth(async () => {
        const response = await apiGet<GeneratedEquation>("/api/equations/generate");
        return response;
      });
    },
    staleTime: 0, // Always fetch fresh data
  });
};
