import { useMutation } from "@tanstack/react-query";
import { useFetchWithAuth } from "./useFetchWithAuth";
import { apiPost } from "@/utils/fetch";
import { EXCEL_FROM_SOURCES_ENDPOINT, LLM_MODELS } from "@/constants";
import { ExcelChartTypeAPI } from "@/types/graph";

interface GenerateGraphData {
  chart: {
    type: ExcelChartTypeAPI;
    reason: string;
    data: {
      range: string[];
      categories: string[];
    };
    title: {
      text: "string";
    };
    axes: {
      x: {
        title: "string";
      };
      y: {
        title: "string";
      };
    };
  };
}

interface GenerateEquationData {
  llmResponse: string;
}

interface ExcelSourcesInput {
  selectedValues: string[][][];
  range: string[];
  userPrompt: string;
}

export type ExcelSourcesResponse =
  | {
      operation: "EQUATION";
      label: string;
      data: GenerateEquationData;
    }
  | {
      operation: "GRAPH";
      label: string;
      data: GenerateGraphData;
    };

export const useExcelSources = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  const mutation = useMutation<ExcelSourcesResponse, Error, ExcelSourcesInput>({
    mutationFn: async ({ selectedValues, range, userPrompt }) => {
      return fetchWithAuth(async () => {
        const response = await apiPost<ExcelSourcesResponse>(EXCEL_FROM_SOURCES_ENDPOINT, {
          userPrompt,
          tableValues: selectedValues,
          range,
          model: LLM_MODELS.DEFAULT,
        });
        return response;
      });
    },
  });

  return mutation;
};
