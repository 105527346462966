import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";

interface ChangePasswordInput {
  password: string;
  newPassword: string;
  repeatedNewPassword: string;
}

interface ChangePasswordResponse {
  ok: boolean;
  message: string;
}

async function changePassword(data: ChangePasswordInput): Promise<ChangePasswordResponse> {
  const response = await apiPost<ChangePasswordResponse>("/api/user/change-password", data);
  return response;
}

export const useChangePassword = () => {
  return useMutation<ChangePasswordResponse, Error, ChangePasswordInput>({
    mutationFn: (data) => changePassword(data),
  });
};
