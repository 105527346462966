import React from "react";

import { MainLayout } from "@/taskpane/layouts/MainLayout";

export const Windows = () => {
  return (
    <MainLayout headerTitle="Window">
      <div className="text-red-500 text-lg">Windows</div>
    </MainLayout>
  );
};
