import { useNavigate } from "react-router-dom";
import { apiGet } from "@/utils/fetch";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { LOGIN_SIGNUP_ROUTE } from "@/constants";

interface AuthSessionResponse {
  ok: boolean;
  message: string;
}

export const useFetchWithAuth = () => {
  const navigate = useNavigate();

  const sessionMutation = useMutation({
    mutationFn: () => apiGet<AuthSessionResponse>("/api/auth/session"),
    onError: () => {
      // Navigate to login and show error if session validation fails
      navigate(LOGIN_SIGNUP_ROUTE);
      toast.error("Session Expired");
    },
  });

  const fetchWithAuth = async <T>(apiCall: () => Promise<T>): Promise<T> => {
    // Trigger the session validation mutation
    const sessionResponse = await sessionMutation.mutateAsync();

    if (sessionResponse.ok) {
      // Call the provided API function if the session is valid
      return await apiCall();
    } else {
      throw new Error("Session expired or invalid");
    }
  };

  return { fetchWithAuth };
};
