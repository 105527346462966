import { useTheme } from "@/providers/ThemeProvider";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface } from "@fluentui/react-components";
import React from "react";

type DialogVariant = "confirmation" | "decision";

interface CustomDialogSurfaceProps {
  onClose: () => void;
  onAction: () => void;
  variant: DialogVariant;
  content: React.ReactNode;
  styles?: React.CSSProperties;
  buttonStyles?: {
    cancel?: React.CSSProperties;
    confirm?: React.CSSProperties;
  };
  contentStyles?: React.CSSProperties;
}

const actionLabelsMap: Record<string, { cancel: string; confirm: string }> = {
  confirmation: { cancel: "Cancel", confirm: "Confirm" },
  decision: { cancel: "Decline", confirm: "Accept Chart" },
  approval: { cancel: "Disapprove", confirm: "Approve" },
  prompt: { cancel: "No", confirm: "Yes" },
};

export const CustomDialogSurface: React.FC<CustomDialogSurfaceProps> = ({
  onClose,
  onAction,
  variant,
  content,
  styles,
  buttonStyles = {},
  contentStyles = {},
}) => {
  const { theme } = useTheme();

  const actionLabels = actionLabelsMap[variant] || actionLabelsMap.confirmation;

  return (
    <DialogSurface
      style={{
        background: "linear-gradient(to right, #38ccd5 0%, #36c7d9 80%)",
        fontFamily: "Switzer, sans-serif",
        maxWidth: "330px",
        borderRadius: "0.8rem",
        color: theme.textColor,
        ...styles,
      }}
    >
      <DialogBody>
        <DialogContent style={{ fontSize: "16px", textAlign: "center", ...contentStyles }}>{content}</DialogContent>
      </DialogBody>
      <DialogActions>
        <div className="flex justify-between w-full text-xs">
          <Button
            onClick={onClose}
            style={{
              borderRadius: "16px",
              border: "1px solid #fff",
              background: buttonStyles.cancel?.background || "transparent",
              ...buttonStyles.cancel,
            }}
          >
            {actionLabels.cancel}
          </Button>
          <Button
            onClick={onAction}
            style={{
              borderRadius: "16px",
              background:
                buttonStyles.confirm?.background || "linear-gradient(to right, #EB3349 0%, #F45C43 51%, #EB3349 100%)",
              backgroundSize: "200% auto",
              padding: "0.5rem 1rem",
              color: "#fff",
              transition: "0.4s",
              ...buttonStyles.confirm,
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundPosition = "right center")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundPosition = "left center")}
          >
            {actionLabels.confirm}
          </Button>
        </div>
      </DialogActions>
    </DialogSurface>
  );
};
