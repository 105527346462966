import React from "react";

export const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center">
      <div
        className="w-[10px] h-[10px] bg-black rounded-full"
        style={{ animation: "pulsing 1.5s infinite ease-in-out" }}
      ></div>
      <style>
        {`
          @keyframes pulsing {
            0%, 100% {
              transform: scale(1);
              opacity: 0.7;
            }
            50% {
              transform: scale(1.5);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingIndicator;
