import { useMediaQuery } from "react-responsive";

// Create a hook that abstracts away different screen size queries
export const useScreenSize = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 350px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 351px) and (max-width: 768px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 769px)" });

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  };
};
