/* global */

import * as React from "react";
import { Button, Field, tokens, makeStyles, Label } from "@fluentui/react-components";
import { useForm, Controller } from "react-hook-form";
import { MainLayout } from "@/taskpane/layouts/MainLayout";
import { MdContentCopy } from "react-icons/md";
import { GlassBackground } from "@/taskpane/components/GlassBackground";
import { useTheme } from "@/providers/ThemeProvider";
// import { useMediaQuery } from "react-responsive";
import { useScreenSize } from "@/hooks/useScreenSize";
import { useGenerateEquation } from "@/hooks/useGenerateEquation";
import LoadingCircle from "@/taskpane/components/LoadingCircle";
import toast from "react-hot-toast";
import { useFetchGeneratedEquation } from "@/hooks/useFetchGeneratedEquation";

interface GenerateEquationProps {
  insertText: (text: string) => void;
}

const useStyles = makeStyles({
  instructions: {
    fontWeight: tokens.fontWeightSemibold,
    marginTop: "20px",
    marginBottom: "10px",
  },
  textAreaField: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  headerText: {
    fontWeight: "bold",
    textAlign: "center",
  },
  label: {
    fontSize: "14px",
    marginBottom: "20px",
    lineHeight: "1.5",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "20px",
  },
});

export const GenerateEquation: React.FC<GenerateEquationProps> = (props: GenerateEquationProps) => {
  const { isSmallScreen } = useScreenSize();
  const { theme } = useTheme();
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { text: "Enter a description...", output: "" },
  });

  const { mutate: generateEquation, isPending, isError } = useGenerateEquation();
  const {
    data: generatedEquation,
    isSuccess: generatedEquationSuccess,
    isError: generatedEquationError,
    isPending: generatedEquationLoading,
  } = useFetchGeneratedEquation();

  React.useEffect(() => {
    if (generatedEquationSuccess) {
      setValue("text", generatedEquation.equations_input); // Pre-fill the text field equations input
      setValue("output", generatedEquation.equations_response); // Pre-fill the equations response

      if (generatedEquation.equations_input === "") {
        setValue("text", "Enter a description...");
        setValue("output", "");
      }
    }

    if (generatedEquationError) {
      setValue("text", "Enter a description...");
      setValue("output", "");
    }
  }, [generatedEquation, generatedEquationSuccess, generatedEquationLoading]);

  const handleGenerateEquation = (data: { text: string }) => {
    setValue("output", "Generating Formula...");
    generateEquation(data, {
      onSuccess: (result) => {
        console.log(result);
        setValue("output", result.llmResponse);
      },
      onError(error) {
        setValue("output", error.message);
        toast.error("Failed to generate formula.");
      },
    });
  };

  const handleCopyToCell = () => {
    toast.success("Copied to clipboard");
    const text = getValues("output");
    props.insertText(text);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent new line in textarea
      handleSubmit(handleGenerateEquation)(); // Trigger form submission
    }
  };

  const styles = useStyles();

  return (
    <MainLayout headerTitle="Generate Formula">
      <GlassBackground>
        <div className="text-white flex flex-col justify-center h-full">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleGenerateEquation)}>
            <Field
              className={styles.textAreaField}
              size="small"
              label={<Label className={styles.label}>Enter a description of the formula</Label>}
            >
              <Controller
                name="text"
                control={control}
                render={({ field }) => (
                  <textarea
                    className="rounded-lg p-2.5 text-white border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] outline-none resize-y overflow-auto"
                    style={{ fontSize: theme.fontSize.medium, fontFamily: theme.fontFamily }}
                    // size="large"
                    rows={7}
                    {...field}
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
              <Button
                appearance="primary"
                disabled={isPending}
                size="large"
                type="submit"
                style={{
                  padding: "0.6rem 2rem",
                  borderRadius: "24px",
                  background: "#080808",
                  fontSize: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isPending ? <LoadingCircle width="16px" height="16px" /> : "Generate"}
              </Button>
            </Field>

            <Field
              className={styles.textAreaField}
              size="small"
              label={<Label className={styles.label}>Generated Formula</Label>}
            >
              <div className={`flex items-center justify-between gap-4 ${isSmallScreen ? "flex-col" : "flex-row"}`}>
                <Controller
                  name="output"
                  control={control}
                  render={({ field }) => (
                    <div
                      className="w-3/5 text-xs rounded-lg border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] outline-none p-5 flex items-center justify-center text-center"
                      style={{
                        color: isError ? theme.error : theme.textColor,
                      }}
                    >
                      {field.value}
                    </div>
                  )}
                />
                <Button
                  appearance="secondary"
                  disabled={false}
                  size="large"
                  onClick={handleCopyToCell}
                  style={{
                    flex: 1,
                    borderRadius: "24px",
                    border: "1px solid #FFFFFF",
                    fontSize: theme.fontSize.small,
                    alignSelf: "center",
                    padding: "10px 10px",
                  }}
                >
                  <MdContentCopy style={{ marginRight: "5px" }} />
                  <span style={{ fontSize: isSmallScreen ? "8px" : "10px" }}>Copy to Cell</span>
                </Button>
              </div>
            </Field>
          </form>
        </div>
      </GlassBackground>
    </MainLayout>
  );
};
