import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

interface ResetPasswordResponse {
  ok: boolean;
  message: string;
}

async function resetPassword(email: string): Promise<ResetPasswordResponse> {
  const response = await apiPost<ResetPasswordResponse>("/api/auth/reset-password", { email });
  return response;
}

export const useResetPassword = () => {
  return useMutation<ResetPasswordResponse, Error, { email: string; setCooldown?: (seconds: number) => void }>({
    mutationFn: ({ email }) => resetPassword(email),
    onSuccess: (data, variables) => {
      if (data.ok) {
        toast.success("Password reset link sent successfully!");

        // Trigger cooldown if setCooldown is provided
        if (variables.setCooldown) {
          variables.setCooldown(59); // Cooldown for 59 seconds
        }
      } else {
        toast.error(data.message || "Invalid email", { duration: 6000 });
      }
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong. Please try again.");
    },
  });
};
