/* global Office Excel */

import { GenerateGraphResponse } from "@/hooks/useGenerateGraph";

export async function insertText(text: string) {
  // Write text to the highlighted cell.
  try {
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.values = [[text]];
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}

export async function insertChart(chartConfig: GenerateGraphResponse["chart"]): Promise<Excel.Chart> {
  return await Excel.run(async (context) => {
    const workbook = context.workbook;
    const sheet = workbook.worksheets.getActiveWorksheet();

    const rangeString = chartConfig.data.range; // Contiguous range, e.g., "A1:B3"
    if (!rangeString) {
      throw new Error("No range data provided for the chart.");
    }

    const excelChartType = chartConfig.type as Excel.ChartType;

    const dataRange = sheet.getRange(rangeString[0]);
    dataRange.load("address, values"); // Load range address and values for debugging/logging
    await context.sync();

    let chart: Excel.Chart;

    if (excelChartType === "Pie" || excelChartType === "Doughnut") {
      // Create Pie or Doughnut chart
      chart = sheet.charts.add(excelChartType, dataRange);
      if (chartConfig.title?.text) {
        chart.title.text = chartConfig.title.text;
      }
      await context.sync();
    } else {
      // For other chart types, use the default behavior
      chart = sheet.charts.add(excelChartType, dataRange, "Auto");

      if (chartConfig.title?.text) {
        chart.title.text = chartConfig.title.text;
      }
      if (chartConfig.axes?.x?.title) {
        chart.axes.categoryAxis.title.text = chartConfig.axes.x.title;
      }
      if (chartConfig.axes?.y?.title) {
        chart.axes.valueAxis.title.text = chartConfig.axes.y.title;
      }

      chart.series.load("name, values, xValues");
      chart.load("chartType, name");
      await context.sync();

      console.log(`Chart created successfully using range: ${dataRange.address}`);
    }

    return chart;
  });
}

export async function insertChartOnSheet(
  chartConfig: GenerateGraphResponse["chart"],
  sheetName: string
): Promise<Excel.Chart> {
  return await Excel.run(async (context) => {
    const workbook = context.workbook;
    const sheet = workbook.worksheets.getItem(sheetName);

    // Validate the provided range in the chart configuration
    const rangeString = chartConfig.data.range; // Contiguous range, e.g., "A1:B3"
    if (!rangeString) {
      throw new Error("No range data provided for the chart.");
    }

    const excelChartType = chartConfig.type as Excel.ChartType;

    // Get the data range from the specified sheet
    const dataRange = sheet.getRange(rangeString[0]);
    dataRange.load("address, values"); // Load range address and values for debugging/logging
    await context.sync();

    let chart: Excel.Chart;

    if (excelChartType === "Pie" || excelChartType === "Doughnut") {
      // Create Pie or Doughnut chart
      chart = sheet.charts.add(excelChartType, dataRange);
      if (chartConfig.title?.text) {
        chart.title.text = chartConfig.title.text;
      }
      await context.sync();
    } else {
      // For other chart types, use the default behavior
      chart = sheet.charts.add(excelChartType, dataRange, "Auto");

      if (chartConfig.title?.text) {
        chart.title.text = chartConfig.title.text;
      }
      if (chartConfig.axes?.x?.title) {
        chart.axes.categoryAxis.title.text = chartConfig.axes.x.title;
      }
      if (chartConfig.axes?.y?.title) {
        chart.axes.valueAxis.title.text = chartConfig.axes.y.title;
      }

      chart.series.load("name, values, xValues");
      chart.load("chartType, name");
      await context.sync();

      console.log(`Chart created successfully on sheet "${sheetName}" using range: ${dataRange.address}`);
    }

    return chart;
  });
}

/**
 * Identifies the default range of the active worksheet that contains non-empty data.
 *
 * This method scans the active worksheet to determine the smallest rectangular range
 * that encompasses all cells with meaningful data (i.e., non-empty cells). It excludes
 * completely empty rows and columns from the range. The resulting range is returned
 * in Excel's address format (e.g., "A1:D10").
 *
 * If the worksheet has no data, an error is thrown.
 *
 * @returns {Promise<string>} The address of the range containing all non-empty data in the active sheet.
 * @throws {Error} If the sheet has no data to create a chart.
 */

export const getDefaultRange = async (): Promise<string> => {
  try {
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const usedRange = sheet.getUsedRange();
      usedRange.load(["values", "address", "rowCount", "columnCount"]);
      await context.sync();

      const values = usedRange.values;
      if (!values || values.length === 0) {
        throw new Error("The sheet has no data to create a chart.");
      }

      let firstRow = -1;
      let lastRow = -1;
      let firstColumn = -1;
      let lastColumn = -1;

      // Find non-empty rows and columns
      for (let row = 0; row < values.length; row++) {
        for (let col = 0; col < values[row].length; col++) {
          if (values[row][col] !== null && values[row][col] !== "") {
            if (firstRow === -1) firstRow = row;
            if (firstColumn === -1 || col < firstColumn) firstColumn = col;
            lastRow = row;
            if (col > lastColumn) lastColumn = col;
          }
        }
      }

      if (firstRow === -1 || firstColumn === -1 || lastRow === -1 || lastColumn === -1) {
        throw new Error("No valid data found in the used range.");
      }

      // Adjust range to Excel's address format
      const startCell = usedRange.getCell(firstRow, firstColumn);
      const endCell = usedRange.getCell(lastRow, lastColumn);

      startCell.load("address");
      endCell.load("address");
      await context.sync();

      const rangeAddress = `${startCell.address.split("!")[1]}:${endCell.address.split("!")[1]}`;
      console.log("Filtered Range Address:", rangeAddress);

      return rangeAddress;
    });
  } catch (error) {
    console.error("Error getting default range:", error);
    return "";
  }
};

/**
 * Identifies the default range of the specified worksheet that contains non-empty data.
 *
 * This method scans the provided worksheet to determine the smallest rectangular range
 * that encompasses all cells with meaningful data (i.e., non-empty cells). It excludes
 * completely empty rows and columns from the range. The resulting range is returned
 * in Excel's address format (e.g., "A1:D10").
 *
 * If the worksheet has no data, an error is thrown.
 *
 * @param {string} sheetName - The name of the worksheet to scan.
 * @returns {Promise<string>} The address of the range containing all non-empty data in the specified sheet.
 * @throws {Error} If the sheet has no data to create a chart.
 */

export const getDefaultRangeForSheet = async (sheetName: string): Promise<string> => {
  try {
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getItem(sheetName); // Get the worksheet by name
      const usedRange = sheet.getUsedRange();
      usedRange.load(["values", "address", "rowCount", "columnCount"]);
      await context.sync();

      const values = usedRange.values;
      if (!values || values.length === 0) {
        throw new Error(`The sheet "${sheetName}" has no data to create a chart.`);
      }

      let firstRow = -1;
      let lastRow = -1;
      let firstColumn = -1;
      let lastColumn = -1;

      // Find non-empty rows and columns
      for (let row = 0; row < values.length; row++) {
        for (let col = 0; col < values[row].length; col++) {
          if (values[row][col] !== null && values[row][col] !== "") {
            if (firstRow === -1) firstRow = row;
            if (firstColumn === -1 || col < firstColumn) firstColumn = col;
            lastRow = row;
            if (col > lastColumn) lastColumn = col;
          }
        }
      }

      if (firstRow === -1 || firstColumn === -1 || lastRow === -1 || lastColumn === -1) {
        throw new Error(`No valid data found in the used range of sheet "${sheetName}".`);
      }

      // Adjust range to Excel's address format
      const startCell = usedRange.getCell(firstRow, firstColumn);
      const endCell = usedRange.getCell(lastRow, lastColumn);

      startCell.load("address");
      endCell.load("address");
      await context.sync();

      const rangeAddress = `${startCell.address.split("!")[1]}:${endCell.address.split("!")[1]}`;
      console.log("Filtered Range Address:", rangeAddress);

      return rangeAddress;
    });
  } catch (error) {
    console.error(`Error getting default range for sheet "${sheetName}":`, error);
    return "";
  }
};

/**
 * For Officejs Excel API
 * Deletes specified sheets from the current workbook.
 *
 * @param {string[]} sheetNames - An array of sheet names to delete.
 * @returns {Promise<void>} A promise that resolves when the sheets are deleted.
 * @throws Will throw an error if a sheet cannot be found or deleted.
 */
export const deleteSheetsFromWorkbook = async (sheetNames: string[]): Promise<void> => {
  return Excel.run(async (context) => {
    const workbook = context.workbook;
    const worksheets = workbook.worksheets;

    // Load the worksheet collection
    worksheets.load("items/name");
    await context.sync();

    // Filter the sheets to delete
    const sheetsToDelete = worksheets.items.filter((sheet) => sheetNames.includes(sheet.name));

    if (sheetsToDelete.length === 0) {
      throw new Error("No matching sheets found to delete.");
    }

    // Delete each sheet
    sheetsToDelete.forEach((sheet) => {
      console.log(`Deleting sheet: ${sheet.name}`);
      sheet.delete();
    });

    await context.sync(); // Commit changes
    console.log("Sheets deleted successfully.");
  });
};

export async function logAllSheetData() {
  try {
    const data = await Excel.run(async (context) => {
      // Get the active worksheet
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      // Get the used range (all data in the sheet)
      const range = sheet.getUsedRange();

      // Load the values of the range
      range.load(["values"]);
      await context.sync();

      // Limit to the first 20 rows
      const limitedData = range.values.slice(0, 20);

      // Log all the data
      console.log("Full sheet data:", limitedData);
      return limitedData;
    });

    return data; // Return data from the Excel.run context
  } catch (error) {
    console.error("Error retrieving sheet data:", error);
    throw error; // Rethrow to let caller handle the error
  }
}

/**
 * Fetches values for the specified ranges or the currently selected ranges if no ranges are provided.
 * @param ranges Optional array of range addresses to fetch values from. If not provided, fetches the selected ranges from the context worksheet.
 * @returns A promise resolving to a 3D array of values (one 2D array for each range).
 */
export const getSelectedValues = async (ranges?: string[]): Promise<string[][][]> => {
  try {
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      let rangeValues: string[][][] = [];

      if (ranges && ranges.length > 0) {
        // Fetch values for each provided range
        rangeValues = await Promise.all(
          ranges.map(async (rangeAddress) => {
            const range = sheet.getRange(rangeAddress);
            range.load("values");
            await context.sync();
            return range.values.map((row) => row.map((cell) => (cell !== null ? cell.toString() : "")));
          })
        );
      } else {
        // If no ranges are provided, use the currently selected ranges
        const rangeAreas = context.workbook.getSelectedRanges();
        rangeAreas.load("areas/items/address,areas/items/values");
        await context.sync();

        // Fetch values for selected ranges
        rangeValues = rangeAreas.areas.items.map((range) => {
          console.log(`Values for range ${range.address}:`, range.values);
          return range.values.map((row) => row.map((cell) => (cell !== null ? cell.toString() : "")));
        });
      }

      console.log("Fetched values for all ranges:", rangeValues);
      return rangeValues;
    });
  } catch (error) {
    console.error("Error fetching values:", error);
    throw error; // Re-throw to let the caller handle the error
  }
};

/**
 * Fetches values for the specified ranges from the worksheet with the given name,
 * or the used range if no ranges are provided.
 * @param sheetName The name of the worksheet from which to fetch the values.
 * @param ranges Optional array of range addresses to fetch values from. If not provided, fetches the used range from the given worksheet.
 * @returns A promise resolving to a 3D array of values (one 2D array for each range).
 */
export const getValuesFromSheetByName = async (sheetName: string, ranges?: string[]): Promise<string[][][]> => {
  try {
    return await Excel.run(async (context) => {
      const workbook = context.workbook;

      // Get the specified worksheet by name
      const sheet = workbook.worksheets.getItem(sheetName);
      sheet.load("name"); // Load the worksheet name to ensure it exists
      await context.sync();

      let rangeValues: string[][][] = [];

      if (ranges && ranges.length > 0) {
        // Fetch values for each provided range
        rangeValues = await Promise.all(
          ranges.map(async (rangeAddress) => {
            const range = sheet.getRange(rangeAddress);
            range.load("values");
            await context.sync();
            return range.values.map((row) => row.map((cell) => (cell !== null ? cell.toString() : "")));
          })
        );
      } else {
        // If no ranges are provided, fetch the used range
        const usedRange = sheet.getUsedRangeOrNullObject();
        usedRange.load("address, values");
        await context.sync();

        if (!usedRange.isNullObject) {
          console.log(`Values for used range ${usedRange.address}:`, usedRange.values);
          rangeValues.push(usedRange.values.map((row) => row.map((cell) => (cell !== null ? cell.toString() : ""))));
        } else {
          console.log(`No used range found in worksheet: ${sheetName}`);
        }
      }

      console.log(`Fetched values from worksheet "${sheetName}":`, rangeValues);
      return rangeValues;
    });
  } catch (error) {
    console.error(`Error fetching values from worksheet "${sheetName}":`, error);
    throw error; // Re-throw to let the caller handle the error
  }
};

/**
 * Gets the selected ranges address.
 * @returns The address of the selected ranges.
 */
export const getSelectedRangeAddress = async (): Promise<string[]> => {
  try {
    return await Excel.run(async (context) => {
      const rangeAreas = context.workbook.getSelectedRanges(); // Get the selected range
      rangeAreas.load("areas/address");
      await context.sync();
      // Extract individual range addresses without the sheet name
      const rangeAddresses = rangeAreas.areas.items.map((range) => {
        return range.address.split("!").pop() || "";
      });

      console.log("Selected range addresses:", rangeAddresses);
      return rangeAddresses;
    });
  } catch (error) {
    console.error("Error getting selected range:", error);
    return [];
  }
};

/**
 * Checks if the specified ranges have any values.
 * @param rangeAddresses An array of range addresses to check (e.g., ["A1:C3", "B2:B4"]).
 * @returns A boolean indicating whether any of the specified ranges have values.
 */
export const checkRangeValues = async (rangeAddresses: string[]): Promise<boolean> => {
  try {
    return await Excel.run(async (context) => {
      if (!rangeAddresses || rangeAddresses.length === 0) {
        return false;
      }

      const sheet = context.workbook.worksheets.getActiveWorksheet();

      // Prepare to load values for each range
      const ranges = rangeAddresses.map((address) => {
        const range = sheet.getRange(address);
        range.load("values");
        return range;
      });

      // Sync to load all values
      await context.sync();

      // Check if any cell in any range has a value
      return ranges.some((range) => range.values.some((row) => row.some((cell) => cell !== null && cell !== "")));
    });
  } catch (error) {
    console.error("Error checking range values:", error);
    return false; // Return false if an error occurs
  }
};

/**
 * Utility function to check if the current platform is Desktop Excel (Windows or Mac).
 *
 * @returns {boolean} - Returns `true` if the add-in is running in Desktop Excel, otherwise `false`.
 *
 * Example usage:
 *
 * if (isDesktopExcel()) {
 *   console.log("Running in Desktop Excel.");
 *   // Enable features specific to Desktop Excel
 * } else {
 *   console.log("Not running in Desktop Excel.");
 *   // Handle other platforms like Excel Online or iOS
 * }
 */
export const isDesktopExcel = (): boolean => {
  return (
    Office.context.host === Office.HostType.Excel &&
    (Office.context.platform === Office.PlatformType.PC || Office.context.platform === Office.PlatformType.Mac)
  );
};

/**
 * Utility function to get the current workbook as a Base64-encoded string. A function with no file.closeAsync
 *
 * @returns {Promise<string>} A promise that resolves to the Base64-encoded string of the workbook.
 * @throws If there is an error retrieving the workbook file or slices.
 */
export const getCurrentWorkbookAsBase64NoClose = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 65536 }, // 64KB chunks
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const file = result.value;
          const sliceCount = file.sliceCount;
          let slicesReceived = 0;
          const fileContent: Uint8Array[] = [];

          const readSlice = (index: number) => {
            file.getSliceAsync(index, (sliceResult) => {
              if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                const sliceData = sliceResult.value.data as ArrayBuffer;
                fileContent.push(new Uint8Array(sliceData));
                slicesReceived++;

                if (slicesReceived === sliceCount) {
                  // Combine all slices into a Blob
                  const completeFile = new Blob(fileContent, {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });

                  // Convert Blob to Base64
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64String = reader.result?.toString().split("base64,")[1];
                    if (base64String) {
                      resolve(base64String);
                    } else {
                      reject(new Error("Failed to convert workbook to Base64."));
                    }
                  };
                  reader.onerror = () => reject(new Error("FileReader failed to read the Blob."));

                  reader.readAsDataURL(completeFile);
                } else {
                  // Read the next slice
                  readSlice(index + 1);
                }
              } else {
                reject(sliceResult.error || new Error("Failed to retrieve a slice of the workbook."));
              }
            });
          };

          // Start reading slices
          readSlice(0);
        } else {
          reject(result.error || new Error("Failed to retrieve the workbook file."));
        }
      }
    );
  });
};

/**
 * Utility function to get the current workbook as a Base64-encoded string. This uses the close.fileAsync to avoid memory exceed limit
 *
 * @returns {Promise<string>} A promise that resolves to the Base64-encoded string of the workbook.
 * @throws If there is an error retrieving the workbook file or slices.
 */
export const getCurrentWorkbookAsBase64 = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 65536 }, // 64KB chunks
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const file = result.value;
          const sliceCount = file.sliceCount;
          let slicesReceived = 0;
          const fileContent: Uint8Array[] = [];

          const readSlice = (index: number) => {
            file.getSliceAsync(index, (sliceResult) => {
              if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                const sliceData = sliceResult.value.data as ArrayBuffer;
                fileContent.push(new Uint8Array(sliceData));
                slicesReceived++;

                if (slicesReceived === sliceCount) {
                  // Combine all slices into a Blob
                  const completeFile = new Blob(fileContent, {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });

                  // Convert Blob to Base64
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64String = reader.result?.toString().split("base64,")[1];
                    if (base64String) {
                      resolve(base64String);
                    } else {
                      reject(new Error("Failed to convert workbook to Base64."));
                    }

                    // Close the file to release memory
                    file.closeAsync(() => {
                      console.log("File resources released successfully.");
                    });
                  };
                  reader.onerror = () => {
                    file.closeAsync(); // Ensure file is closed on error
                    reject(new Error("FileReader failed to read the Blob."));
                  };

                  reader.readAsDataURL(completeFile);
                } else {
                  // Read the next slice
                  readSlice(index + 1);
                }
              } else {
                file.closeAsync(); // Ensure file is closed on error
                reject(sliceResult.error || new Error("Failed to retrieve a slice of the workbook."));
              }
            });
          };

          // Start reading slices
          readSlice(0);
        } else {
          reject(result.error || new Error("Failed to retrieve the workbook file."));
        }
      }
    );
  });
};

/**
 * Creates a downloadable URL for the current Excel workbook.
 *
 * @returns {Promise<string>} A promise that resolves to a URL for downloading the workbook.
 * @throws If there is an error retrieving or processing the workbook.
 */
export const createDownloadableWorkbookUrl = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 65536 }, // 64KB chunks
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const file = result.value;
          const sliceCount = file.sliceCount;
          let slicesReceived = 0;
          const fileContent: Uint8Array[] = [];

          const readSlice = (index: number) => {
            file.getSliceAsync(index, (sliceResult) => {
              if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                const sliceData = sliceResult.value.data as ArrayBuffer;
                fileContent.push(new Uint8Array(sliceData));
                slicesReceived++;

                if (slicesReceived === sliceCount) {
                  // Combine all slices into a single Uint8Array
                  const combinedArray = fileContent.reduce((acc, slice) => {
                    const combined = new Uint8Array(acc.length + slice.length);
                    combined.set(acc);
                    combined.set(slice, acc.length);
                    return combined;
                  }, new Uint8Array(0));

                  // Create a Blob from the combined Uint8Array
                  const blob = new Blob([combinedArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });

                  // Create a downloadable URL
                  const url = URL.createObjectURL(blob);

                  // Release the file after processing
                  file.closeAsync();

                  resolve(url); // Resolve with the URL
                } else {
                  // Read the next slice
                  readSlice(index + 1);
                }
              } else {
                file.closeAsync();
                reject(sliceResult.error || new Error("Failed to retrieve a slice of the workbook."));
              }
            });
          };

          // Start reading slices
          readSlice(0);
        } else {
          reject(result.error || new Error("Failed to retrieve the workbook file."));
        }
      }
    );
  });
};
