/* global Excel */

import { getCurrentWorkbookAsBase64 } from "@/taskpane/taskpane";
import * as ExcelJS from "exceljs";
import toast from "react-hot-toast";

// These utility functions are more focused on ExcelJS library, but we may use this in the future when we want to keep
// the images, existing charts but for now I've found a way to
// copy the uploaded file to the current workbook instance

export interface ProcessedCell {
  data: any; // The value of the cell (can be string, number, formula, etc.)
  cell: string; // The cell reference (e.g., "A1")
  font: {
    bold: boolean;
    italic: boolean;
    size: number;
    color: string; // RGB format (e.g., "000000" for black)
  };
}

export const getUsedRangeValues = (sheet: ExcelJS.Worksheet) => {
  let startRow = Infinity;
  let startCol = Infinity;
  let endRow = -Infinity;
  let endCol = -Infinity;

  // Determine the range of non-empty cells
  sheet.eachRow((row, rowIndex) => {
    row.eachCell((cell, colIndex) => {
      if (cell.value !== null && cell.value !== undefined && cell.value !== "") {
        startRow = Math.min(startRow, rowIndex);
        startCol = Math.min(startCol, colIndex);
        endRow = Math.max(endRow, rowIndex);
        endCol = Math.max(endCol, colIndex);
      }
    });
  });

  if (startRow === Infinity || startCol === Infinity) {
    // No data in the sheet
    return [];
  }

  //Ignore eslint error, it is confused that it's using the office js, we are using exceljs
  // Extract values within the used range
  const usedRangeValues = [];
  for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
    const row = sheet.getRow(rowIndex);
    const rowData = [];

    for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
      const cell = row.getCell(colIndex);

      let cellValue = cell.value;

      if (cellValue instanceof Date) {
        // Convert date to ISO string
        cellValue = cellValue.toISOString();
      } else if (typeof cellValue === "object" && cellValue !== null) {
        // Handle rich text by extracting only the plain text
        if (cellValue.richText) {
          cellValue = cellValue.richText.map((textPart) => textPart.text).join("");
        } else if (cellValue.formula) {
          // If it's a formula, use the result if available, otherwise the formula itself
          cellValue = cellValue.result !== undefined ? cellValue.result.toString() : cellValue.formula;
        } else {
          // For any other object, convert to an empty string
          cellValue = "";
        }
      } else if (cellValue === null || cellValue === undefined) {
        // Default empty cells to an empty string
        cellValue = "";
      } else {
        // Convert all other types (numbers, booleans, etc.) to strings
        cellValue = cellValue.toString();
      }

      rowData.push(cellValue);
    }

    usedRangeValues.push(rowData);
  }

  return usedRangeValues;
};

/**
 * Calculates the data range (e.g., "A1:D10") of the given worksheet.
 * @param worksheet - The ExcelJS Worksheet instance.
 * @returns The range string.
 */
export const getWorksheetRange = (worksheet: ExcelJS.Worksheet): string => {
  let startRow = Infinity;
  let startCol = Infinity;
  let endRow = -Infinity;
  let endCol = -Infinity;

  worksheet.eachRow((row, rowIndex) => {
    row.eachCell((cell, colIndex) => {
      if (cell.value !== null && cell.value !== undefined && cell.value !== "") {
        startRow = Math.min(startRow, rowIndex);
        startCol = Math.min(startCol, colIndex);
        endRow = Math.max(endRow, rowIndex);
        endCol = Math.max(endCol, colIndex);
      }
    });
  });

  if (startRow === Infinity || startCol === Infinity) {
    throw new Error("No valid data found in the worksheet.");
  }

  const startCell = worksheet.getRow(startRow).getCell(startCol).address;
  const endCell = worksheet.getRow(endRow).getCell(endCol).address;

  return `${startCell}:${endCell}`;
};

/**
 * A function for ExcelJS workbook instance
 * Appends a formula and label to the first row of the first available free column in the worksheet.
 * @param worksheet - The ExcelJS Worksheet instance.
 * @param label - The label text (e.g., "Formula").
 * @param formula - The Excel formula to append.
 * @returns Object containing the addresses of the formula and label cells.
 */
export const appendFormulaToWorksheet = (
  worksheet: ExcelJS.Worksheet,
  label: string,
  formula: string
): { formulaCellAddress: string; labelCellAddress: string } => {
  // Find the first available free column
  let freeColumn = 1; // Start from column 1 (A)
  while (worksheet.getCell(1, freeColumn).value) {
    freeColumn++;
  }

  // Assign cells for label and formula
  const labelCell = worksheet.getCell(1, freeColumn);
  const formulaCell = worksheet.getCell(1, freeColumn + 1);

  // Set cell formats and values
  labelCell.numFmt = "@"; // Text format for label
  formulaCell.numFmt = "General"; // General format for formula
  labelCell.value = `${label}:`;
  formulaCell.value = { formula };

  // Log worksheet state (optional for debugging)
  console.log(worksheet.getSheetValues());

  return {
    // eslint-disable-next-line office-addins/call-sync-before-read, office-addins/load-object-before-read
    formulaCellAddress: formulaCell.address,
    // eslint-disable-next-line office-addins/call-sync-before-read, office-addins/load-object-before-read
    labelCellAddress: labelCell.address,
  };
};

/**
 * A function for OfficeJS workbook instance
 * Appends a formula and label to the first row of the first available free column in the worksheet.
 *
 * @param {Excel.Worksheet} worksheet - The Office.js Worksheet instance.
 * @param {string} label - The label text (e.g., "Formula").
 * @param {string} formula - The Excel formula to append.
 * @returns {Promise<{ formulaCellAddress: string; labelCellAddress: string }>}
 *          The addresses of the formula and label cells.
 */
export const appendFormulaToOfficeWorksheet = async (
  sheetName: string,
  label: string,
  formula: string
): Promise<{ formulaCellAddress: string; labelCellAddress: string }> => {
  return Excel.run(async (context) => {
    // Load the used range to identify the first available free column
    const worksheet = context.workbook.worksheets.getItem(sheetName);
    const usedRange = worksheet.getUsedRange();
    usedRange.load("columnCount");
    await context.sync();

    const freeColumn = usedRange.columnCount + 1; // First free column (1-based index)

    // Set the label in the free column
    const labelCell = worksheet.getCell(0, freeColumn - 1); // Row 1, Free Column
    labelCell.values = [[`${label}:`]]; // Set the label text
    labelCell.numberFormat = [["@"]]; // Set the format to text

    // Set the formula in the next column
    const formulaCell = worksheet.getCell(0, freeColumn); // Row 1, Next Column
    formulaCell.formulas = [[formula]]; // Set the formula
    formulaCell.numberFormat = [["General"]]; // Set the format to General

    // Load the addresses of both cells for further use
    labelCell.load("address");
    formulaCell.load("address");

    // Sync changes to ensure the values are set
    await context.sync();

    return {
      formulaCellAddress: formulaCell.address,
      labelCellAddress: labelCell.address,
    };
  });
};

/**
 * Converts CSV text into an ExcelJS Worksheet and returns the workbook.
 * @param csvText - The raw CSV text content.
 * @returns The ExcelJS Workbook containing the worksheet with the CSV data.
 */
export const convertCsvToWorksheet = (csvText: string): ExcelJS.Workbook => {
  const workbook = new ExcelJS.Workbook();

  // Add a new worksheet for the CSV data
  const worksheet = workbook.addWorksheet("CSV Data");

  const rows = csvText.split("\n").map((row) => row.split(","));
  rows.forEach((row) => {
    const addedRow = worksheet.addRow(row);

    row.forEach((cellValue, colIndex) => {
      const cell = addedRow.getCell(colIndex + 1);

      if (cellValue.trim() === "") {
        cell.value = null; // Handle empty cells
      } else if (!isNaN(Number(cellValue)) && cellValue.trim() !== "") {
        cell.value = parseFloat(cellValue); // Handle numbers
      } else if (Date.parse(cellValue)) {
        const date = new Date(cellValue);
        if (!isNaN(date.getTime())) {
          cell.value = date;
          cell.numFmt = "mm/dd/yyyy"; // Excel date format
        } else {
          cell.value = cellValue; // Invalid dates are treated as text
        }
      } else {
        cell.value = cellValue; // Handle text
      }
    });
  });

  return workbook;
};

/**
 * Process data from ExcelJS workbook into a structured format.
 * @param worksheet - The ExcelJS worksheet to process.
 * @returns Processed data including cell values and styles.
 */
export const processExcelJSData = (worksheet: ExcelJS.Worksheet): ProcessedCell[] => {
  const processedData: ProcessedCell[] = [];

  worksheet.eachRow((row, rowIndex) => {
    row.eachCell((cell, colIndex) => {
      if (cell.value === null || cell.value === undefined) return;

      const columnLetter = String.fromCharCode(64 + colIndex);
      const cellRef = `${columnLetter}${rowIndex}`;

      // Extract font information if available
      const font = cell.style?.font || {};

      processedData.push({
        data: cell.value,
        cell: cellRef,
        font: {
          bold: font.bold || false,
          italic: font.italic || false,
          size: font.size || 11,
          color: font.color?.argb?.substring(2) || "000000", // Remove the "FF" prefix for RGB format
        },
      });
    });
  });

  return processedData;
};

/**
 * Write processed data into an OfficeJS worksheet with formatting.
 * @param context - OfficeJS Excel request context.
 * @param processedData - Array of processed cell data.
 */
export const writeToOfficeJSWorksheet = async (context: Excel.RequestContext, processedData: ProcessedCell[]) => {
  const officeWorksheet = context.workbook.worksheets.add("CopiedData");

  processedData.forEach(({ data, cell, font }) => {
    const officeCell = officeWorksheet.getRange(cell);

    // Set cell value
    if (data.sharedFormula) {
      // Handle shared formulas
      const masterCellFormula = data.sharedFormula.startsWith("=") ? data.sharedFormula : `=${data.sharedFormula}`;
      officeCell.formulas = [[masterCellFormula]]; // Use the shared formula
      officeCell.values = [[data.result !== undefined && data.result !== null ? data.result : 0]];
    } else if (data.formula) {
      // Handle regular formulas
      const formulaWithEquals = data.formula.startsWith("=") ? data.formula : `=${data.formula}`;
      officeCell.formulas = [[formulaWithEquals]];
    } else if (data instanceof Date) {
      // Handle Date
      const excelDate = (data.getTime() - new Date(Date.UTC(1899, 11, 30)).getTime()) / (1000 * 60 * 60 * 24);
      officeCell.values = [[excelDate]];
      officeCell.numberFormat = [["yyyy-mm-dd"]];
    } else if (typeof data === "string") {
      officeCell.values = [[data]];
    } else if (typeof data === "number" || data === 0) {
      officeCell.values = [[data]];
    } else if (data.richText) {
      const richText = data.richText.map((segment) => segment.text).join("");
      officeCell.values = [[richText]];
    } else {
      // Fallback for unsupported data
      officeCell.values = [["Unsupported Data"]];
    }

    // Apply formatting
    officeCell.format.font.bold = font.bold;
    officeCell.format.font.italic = font.italic;
    officeCell.format.font.size = font.size;
    officeCell.format.font.color = font.color;
  });

  await context.sync();
};

//A code for desktop excel to open a new workbook with the current workbook
// Remove the metadata before the base64-encoded string.
// let startIndex = reader.result.toString().indexOf("base64,");
// let externalWorkbook = reader.result.toString().substr(startIndex + 7);
// const base64boy = await getCurrentWorkbookAsBase64();

// Excel.createWorkbook(base64boy);
// return context.sync();

/**
 * A code for desktop excel to open a new workbook with the current workbook
 * Copies the current workbook to a new workbook.
 */
export const createNewWorkbookFromCurrent = async (addedSheetNames: string[], originalSheetNames: string[]) => {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;

      // Load all worksheets in the workbook
      const worksheets = workbook.worksheets;
      worksheets.load("items/name, items/visibility");
      await context.sync();

      // Step 1: Modify visibility for the new workbook
      worksheets.items.forEach((sheet) => {
        if (originalSheetNames.includes(sheet.name)) {
          // Hide original sheets (except _temp_chart_data)
          if (!/^_temp_chart_data( \(\d+\))?$/.test(sheet.name)) {
            const originalSheet = workbook.worksheets.getItem(sheet.name);
            originalSheet.visibility = Excel.SheetVisibility.hidden;
          }
        } else if (addedSheetNames.includes(sheet.name)) {
          // Reveal added sheets
          const addedSheet = workbook.worksheets.getItem(sheet.name);
          addedSheet.visibility = Excel.SheetVisibility.visible;
        }
      });
      await context.sync();

      console.log("Original sheets hidden and added sheets revealed for new workbook.");

      // Step 2: Get the current workbook as a Base64 string
      const base64string = await getCurrentWorkbookAsBase64();

      if (base64string) {
        // Step 3: Create a new workbook from the Base64 string
        Excel.createWorkbook(base64string);
        console.log("Successfully copied the current workbook to a new workbook.");
      } else {
        console.error("Failed to retrieve the current workbook.");
      }

      // Step 4: Hide added sheets in the current workbook
      addedSheetNames.forEach((sheetName) => {
        if (!/^_temp_chart_data( \(\d+\))?$/.test(sheetName)) {
          const addedSheet = workbook.worksheets.getItem(sheetName);
          addedSheet.visibility = Excel.SheetVisibility.hidden; // Hide the added sheet
        }
      });
      await context.sync();
      console.log("Added sheets hidden in the current workbook.");
    });
  } catch (error) {
    console.error("Something went wrong transferring to a new workbook:", error);
  } finally {
    try {
      await Excel.run(async (context) => {
        const workbook = context.workbook;

        // Load all worksheets in the workbook
        const worksheets = workbook.worksheets;
        worksheets.load("items/name, items/visibility");
        await context.sync();

        // Step 5: Restore visibility of original sheets
        worksheets.items.forEach((sheet) => {
          if (originalSheetNames.includes(sheet.name)) {
            if (!/^_temp_chart_data( \(\d+\))?$/.test(sheet.name)) {
              const originalSheet = workbook.worksheets.getItem(sheet.name);
              originalSheet.visibility = Excel.SheetVisibility.visible;
            }
          }
        });
        await context.sync();

        console.log("Original sheets visibility restored.");
      });
    } catch (cleanupError) {
      console.error("Error restoring visibility of original sheets:", cleanupError);
    }
  }
};

/**
 * Utility function to copy sheets from a given file into the current workbook.
 *
 * @param {File} file - The file from which to copy the sheets.
 * @param {boolean} hideSheets - Optional parameter to hide the sheets after adding them (default: false).
 * @throws Will throw an error if the file cannot be processed or sheets cannot be inserted.
 */
export const copySheetsToCurrentWorkbook = async (file: File, hideSheets: boolean = false): Promise<void> => {
  if (!file) {
    throw new Error("No file provided to copy sheets from.");
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64String = reader.result?.toString().split("base64,")[1];
        if (!base64String) {
          throw new Error("Failed to read file as base64.");
        }

        await Excel.run(async (context) => {
          const workbook = context.workbook;

          // Load existing worksheet names
          const worksheets = workbook.worksheets;
          worksheets.load("items/name");
          await context.sync();

          const initialSheetNames = worksheets.items.map((sheet) => sheet.name);
          const addedSheets: Excel.Worksheet[] = [];

          const getUniqueSheetName = (name: string): string => {
            let uniqueName = `sheetshark-${name}`;
            let counter = 1;

            while (initialSheetNames.includes(uniqueName) || addedSheets.some((sheet) => sheet.name === uniqueName)) {
              uniqueName = `sheetshark-${name} (${counter++})`;
            }

            return uniqueName;
          };

          try {
            // Insert the worksheets from the base64 file
            const options: Excel.InsertWorksheetOptions = {
              sheetNamesToInsert: [], // Insert all worksheets from the file
            };
            workbook.insertWorksheetsFromBase64(base64String, options);
            await context.sync();

            // Reload worksheets to identify newly added ones
            worksheets.load("items/name");
            await context.sync();

            const newSheets = worksheets.items.filter(
              (sheet) => !initialSheetNames.includes(sheet.name) && !/^_temp_chart_data( \(\d+\))?$/.test(sheet.name)
            );

            // Add unique names to each new sheet and track them
            newSheets.forEach((sheet) => {
              const uniqueName = getUniqueSheetName(sheet.name);
              sheet.name = uniqueName;
              addedSheets.push(sheet);
            });
            await context.sync();

            if (hideSheets) {
              // Set visibility of new sheets to hidden
              addedSheets.forEach((sheet) => {
                sheet.visibility = Excel.SheetVisibility.hidden;
              });

              await context.sync();
            }

            resolve();
          } catch (innerError) {
            // Delete any sheets that were added before the error
            console.error("Error during worksheet insertion, cleaning up added sheets:", innerError);
            addedSheets.forEach((sheet) => sheet.delete());
            await context.sync();
            throw innerError; // Re-throw the error to propagate it
          }
        });
      } catch (error) {
        console.error("Error copying sheets to the current workbook:", error);
        toast.error(error.message, { duration: 7000 });
        reject(error);
      }
    };

    // Trigger FileReader to read the file
    reader.readAsDataURL(file);
  });
};

/**
 * Utility function to unhide all hidden worksheets in the current workbook.
 *
 * @returns {Promise<void>} Resolves when all hidden worksheets have been made visible.
 * @throws Will throw an error if the operation fails.
 */
export const unhideWorksheets = async (): Promise<void> => {
  try {
    await Excel.run(async (context) => {
      const workbook = context.workbook;
      const worksheets = workbook.worksheets;

      // Load worksheet visibility information
      worksheets.load("items/name, items/visibility");
      await context.sync();

      // Find hidden worksheets and unhide them
      worksheets.items.forEach((sheet) => {
        if (sheet.visibility === Excel.SheetVisibility.hidden) {
          sheet.visibility = Excel.SheetVisibility.visible;
        }
      });

      await context.sync();

      console.log("All hidden worksheets have been made visible.");
    });
  } catch (error) {
    console.error("Error unhiding worksheets:", error);
    throw new Error("Failed to unhide worksheets.");
  }
};
