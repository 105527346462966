import React from "react";
import { MainLayout } from "@/taskpane/layouts/MainLayout";
import { CheckmarkCircle20Regular } from "@fluentui/react-icons";
import { plans } from "@/constants";

export const Plans = () => {
  return (
    <MainLayout headerTitle="Plans">
      <div className="text-center mb-16 text-white">
        <h1 className="text-2xl font-bold">Choose Your Plan</h1>
        <p className="text-sm opacity-90 max-w-xl mx-auto mt-3">
          Choose the plan that best supports your Excel workflow and boost your productivity.
        </p>
      </div>

      <div className="grid gap-6 max-w-6xl mx-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {plans.map((plan) => {
          const isHighlight = plan.highlight;

          return (
            <div
              key={plan.name}
              className={`w-full rounded-xl p-5 flex flex-col justify-between relative h-full ${
                isHighlight ? "bg-[#1a2942] text-white shadow-xl z-10" : "bg-white text-[#1a1a1a] shadow"
              }`}
            >
              {plan.badge && (
                <div className="absolute top-0 right-0 bg-[#22c9b6] text-white text-xs font-bold px-2 py-1 rounded-bl-md">
                  {plan.badge}
                </div>
              )}

              <div className="flex flex-col flex-grow">
                <div className="text-center mb-4">
                  <h3 className="text-base font-bold">{plan.name}</h3>
                  <p className={`text-xs mt-1 ${isHighlight ? "text-blue-200/80" : "text-gray-500"}`}>
                    {plan.description}
                  </p>
                </div>

                <div className="text-center mb-6">
                  <span className="text-xl font-bold">${plan.price}</span>
                  <span className={`text-xs ml-1 ${isHighlight ? "text-blue-200/80" : "text-gray-500"}`}>/month</span>
                </div>

                <ul className="space-y-4 mb-6 flex-grow">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <div
                        className={`min-w-[20px] min-h-[20px] rounded-full flex items-center justify-center mt-1 ${
                          isHighlight ? "bg-[#22c9b6]/20" : "bg-[#e6f7f5]"
                        }`}
                      >
                        <CheckmarkCircle20Regular className="text-[#22c9b6]" />
                      </div>
                      <div className="ml-2">
                        <div className="font-semibold text-xs">{feature.title}</div>
                        <div className={`text-[11px] mt-1 ${isHighlight ? "text-blue-200/80" : "text-gray-500"}`}>
                          {feature.sub}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {plan.current ? (
                <div
                  className={`w-full py-2.5 rounded-lg font-semibold text-xs text-center mt-auto cursor-default ${
                    isHighlight ? "bg-white text-[#1a2942]" : "bg-gray-200 text-gray-500"
                  }`}
                >
                  Current Plan
                </div>
              ) : (
                <button
                  className={`w-full py-2.5 rounded-lg font-semibold text-xs transition-opacity duration-300 shadow mt-auto ${
                    isHighlight
                      ? "bg-white text-[#1a2942] hover:bg-gray-100"
                      : "text-white bg-gradient-to-r from-[#22c9b6] to-[#1e88e5] hover:opacity-90"
                  }`}
                >
                  Get Started
                </button>
              )}
            </div>
          );
        })}
      </div>
    </MainLayout>
  );
};
