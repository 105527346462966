import { GENERATE_EQUATION_ROUTE, VERIFY_EMAIL_ENDPOINT } from "@/constants";
import { apiPost } from "@/utils/fetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface EmailVerificationResponse {
  ok: boolean;
  message: string;
  email: string;
}

interface EmailVerificationInput {
  token: string;
}

const emailVerification = async (token: string): Promise<EmailVerificationResponse> => {
  return apiPost(`${VERIFY_EMAIL_ENDPOINT}/${token}`, {});
};

export const useEmailVerification = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<EmailVerificationResponse, Error, EmailVerificationInput>({
    mutationFn: ({ token }) => emailVerification(token),
    onSuccess: (data) => {
      if (data.ok) {
        toast.success("Email has been verified");
        queryClient.invalidateQueries({ queryKey: ["userInfo"] });
        navigate(GENERATE_EQUATION_ROUTE);
      } else {
        toast.error("Invalid verification token", { duration: 6000 });
      }
    },
    onError: () => {
      toast.error("Invalid verification token");
    },
  });
};
