import { Button, Image } from "@fluentui/react-components";
import React, { useState } from "react";
import LoadingCircle from "./LoadingCircle";
import toast from "react-hot-toast";

import SheetShark from "@/assets/sheetshark_logo_main_stacked_white.svg";
import { useTheme } from "@/providers/ThemeProvider";
import { useLogout } from "@/hooks/useLogout";
import { useChangePassword } from "@/hooks/useChangePassword";
import { useNavigate } from "react-router-dom";
import { GENERATE_EQUATION_ROUTE } from "@/constants";

export const ChangePasswordView = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedNewPassword, setRepeatedNewPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const { mutate: changePassword, isPending } = useChangePassword();
  const { mutate: logout, isPending: isLoggingOut } = useLogout();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError(false); // Reset error state

    if (newPassword !== repeatedNewPassword) {
      setPasswordError(true);
      toast.error("New passwords do not match!");
      return;
    }

    changePassword(
      { password, newPassword, repeatedNewPassword },
      {
        onSuccess: (data) => {
          if (data.ok) {
            toast.success("Password changed successfully!");
            navigate(GENERATE_EQUATION_ROUTE);
          } else {
            toast.error(data.message || "Password change failed.");
          }
        },
        onError: (error) => {
          toast.error(error.message || "An unexpected error occurred.");
        },
      }
    );
  };

  const handleBackToLogin = () => {
    logout();
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full gap-4 max-w-sm px-4">
        <div className="flex flex-col items-center">
          <Image src={SheetShark} alt="SheetShark Logo" height={140} width={140} />
          <h2 style={{ color: theme.textColor }} className="text-sm">
            Change Password
          </h2>
          <h3 className="text-xs text-gray-300 mt-1">Enter your current and new password</h3>
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label className=" text-white" style={{ fontSize: theme.fontSize.medium }}>
            Current Password
          </label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            disabled={isPending}
            className="w-full px-5 py-2 text-sm rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none placeholder-white"
            placeholder="Enter current password"
            type="password"
            value={password}
            required
          />
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label className=" text-white" style={{ fontSize: theme.fontSize.medium }}>
            New Password
          </label>
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            disabled={isPending}
            className={`w-full px-5 py-2 rounded-lg border text-sm ${
              passwordError ? "border-red-500" : "border-gray-300/40"
            } bg-gray-300/30 text-white outline-none placeholder-white`}
            placeholder="Enter new password"
            type="password"
            value={newPassword}
            required
          />
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label className=" text-white" style={{ fontSize: theme.fontSize.medium }}>
            Confirm Password
          </label>
          <input
            onChange={(e) => setRepeatedNewPassword(e.target.value)}
            disabled={isPending}
            className={`w-full px-5 py-2 rounded-lg border text-sm ${
              passwordError ? "border-red-500" : "border-gray-300/40"
            } bg-gray-300/30 text-white outline-none placeholder-white`}
            placeholder="Confirm new password"
            type="password"
            value={repeatedNewPassword}
            required
          />
        </div>

        <Button
          appearance="primary"
          disabled={isPending || isLoggingOut}
          size="large"
          type="submit"
          style={{
            width: "100%",
            color: "white",
            padding: "0.6rem 1rem",
            borderRadius: "8px",
            background: "#080808",
            fontSize: "12px",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isPending ? <LoadingCircle width="16px" height="16px" /> : "Change Password"}
        </Button>

        <button
          disabled={isLoggingOut}
          type="button"
          onClick={handleBackToLogin}
          className="text-xs hover:underline text-white"
        >
          {isLoggingOut ? <LoadingCircle width="12px" height="12px" /> : <span>Go back to login</span>}
        </button>
      </form>
    </div>
  );
};
