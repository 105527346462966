/* global console fetch RequestInit  HeadersInit ReadableStream OfficeRuntime*/

import { generateFullUrl } from "@/utils/misc";
import { type HttpMethod } from "@/types";
import { generateDialogMessageForError } from "@/utils/misc";
import { showExcelDialog } from "@/utils/office";
import { APIError, EmailExistError, NotFoundError } from "@/utils/errors";
import { getEnvVariable } from "@/utils/env";

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
  queryParams?: Record<string, string | number>;
  isStreaming?: boolean;
}

export async function apiFetch<T>(url: string, method: HttpMethod, options?: FetchOptions): Promise<T> {
  // check if it's a relative url and prepend the base url
  url = getEnvVariable("REACT_APP_API_BASE_URL") + url;
  // handle query params if they exist
  if (options?.queryParams) {
    url = generateFullUrl(url, options.queryParams);
  }

  // Retrieve the token from Office runtime (if available)
  const storedToken = await OfficeRuntime.storage.getItem("authToken");

  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(storedToken ? { Authorization: storedToken } : {}),
      ...(options?.headers || {}),
    },
    credentials: "include", // Need this
    ...options,
  });

  // Check if the Authorization header exists in the response
  const authorizationHeader = response.headers.get("authorization");

  // If the Authorization header is present, store the token in Office runtime
  if (authorizationHeader) {
    const token = authorizationHeader.split(" ")[1]; // Extract the token from "Bearer <token>"
    if (token) {
      // Store the token in Office runtime
      await OfficeRuntime.storage.setItem("authToken", `Bearer ${token}`);
      console.log("Token stored in OfficeRuntime storage:", token);
    }
  }

  if (!response.ok) {
    try {
      let errorMessage = response.statusText;
      let detailedMessage: string | undefined;
      try {
        const errorResponse = await response.json();
        errorMessage = errorResponse.message || response.statusText || errorResponse.detail;
        detailedMessage = errorResponse.detail;
      } catch (e) {
        // ignore the error
      }
      // special case errors for 4xx
      // TODO: add more special cases
      if (response.status === 404) {
        throw new NotFoundError({ message: errorMessage, url });
      }

      if (response.status === 409) {
        throw new EmailExistError({ message: errorMessage, url });
      }
      // may need to modify this logic
      throw new APIError({
        status: response.status,
        message: errorMessage || response.statusText,
        detailedMessage: detailedMessage,
        url: response.url,
      });
    } catch (e) {
      // console and re-throw the error
      console.error("Api response error", e);
      throw e;
    }
  }

  const data = await response.json();
  return data as T;
}

export async function apiFetchWithStreaming(
  url: string,
  method: HttpMethod,
  options?: FetchOptions
): Promise<ReadableStream<Uint8Array>> {
  url = getEnvVariable("REACT_APP_API_BASE_URL") + url;

  // handle query params if they exist
  if (options?.queryParams) {
    url = generateFullUrl(url, options.queryParams);
  }

  // Retrieve the token from Office runtime (if available)
  const storedToken = await OfficeRuntime.storage.getItem("authToken");

  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(storedToken ? { Authorization: storedToken } : {}),
      ...(options?.headers || {}),
    },
    credentials: "include", // Need this
    ...options,
  });

  if (!response.ok) {
    try {
      let errorMessage = response.statusText;
      let detailedMessage: string | undefined;
      try {
        const errorResponse = await response.json();
        errorMessage = errorResponse.message || response.statusText || errorResponse.detail;
        detailedMessage = errorResponse.detail;
      } catch (e) {
        // ignore the error
      }
      // special case errors for 4xx
      // TODO: add more special cases
      if (response.status === 404) {
        throw new NotFoundError({ message: errorMessage, url });
      }

      if (response.status === 409) {
        throw new EmailExistError({ message: errorMessage, url });
      }
      // may need to modify this logic
      throw new APIError({
        status: response.status,
        message: errorMessage || response.statusText,
        detailedMessage: detailedMessage,
        url: response.url,
      });
    } catch (e) {
      // console and re-throw the error
      console.error("Api response error", e);
      throw e;
    }
  }

  if (!response.body) {
    throw new Error("Streaming response has no body");
  }
  return response.body as ReadableStream<Uint8Array>; // Return both the stream and the JSON data
}

export async function handleApiCall<T>(
  apiCall: Promise<T>,
  options?: {
    onApiSuccess?: (result: T) => Promise<void> | void;
    onApiFailure?: (error: any) => Promise<void> | void; // TODO: fix tyepe of error of apiFailure
    onFinally?: () => Promise<void> | void;
  }
) {
  const { onApiSuccess, onApiFailure, onFinally } = options || {};
  try {
    const result = await apiCall;
    await onApiSuccess?.(result);
  } catch (error) {
    // if we have the callback for api errors, then handle that
    if (onApiFailure && error instanceof APIError) {
      console.log("calling on api failure", error);
      await onApiFailure(error);
    } else {
      await showExcelDialog({ dialogMessage: generateDialogMessageForError(error) });
    }
  } finally {
    await onFinally?.();
  }
}

// Boilerplate code
export async function apiGet<T>(url: string, options?: FetchOptions): Promise<T> {
  return apiFetch<T>(url, "GET", options);
}
export async function apiPostStreaming(
  url: string,
  body: any,
  options?: FetchOptions
): Promise<ReadableStream<Uint8Array>> {
  return apiFetchWithStreaming(url, "POST", { ...options, body: JSON.stringify(body) });
}

export async function apiPost<T>(url: string, body: any, options?: FetchOptions): Promise<T> {
  return apiFetch<T>(url, "POST", { ...options, body: JSON.stringify(body) });
}

export async function apiPut<T>(url: string, body: any, options?: FetchOptions): Promise<T> {
  return apiFetch<T>(url, "PUT", { ...options, body: JSON.stringify(body) });
}

export async function apiDelete<T>(url: string, options?: FetchOptions): Promise<T> {
  return apiFetch<T>(url, "DELETE", options);
}

export async function apiPatch<T>(url: string, body: any, options?: FetchOptions): Promise<T> {
  return apiFetch<T>(url, "PATCH", { ...options, body: JSON.stringify(body) });
}
