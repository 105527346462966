import React, { useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@/providers/ThemeProvider";

// import { PiHouseThin } from "react-icons/pi";
import { PiNotePencilThin } from "react-icons/pi";

// import { PiLighthouseThin } from "react-icons/pi";
import { PiChatsCircleThin } from "react-icons/pi";

// import { PiWindowsLogoLight } from "react-icons/pi";

import { PiFileXlsThin } from "react-icons/pi";

import { PiGraphThin } from "react-icons/pi";

import { PiUserThin } from "react-icons/pi";

import { useMediaQuery } from "react-responsive";
import UserDrawer from "./UserDrawer";

// Define static styles that don't depend on dynamic values
const useStyles = makeStyles({
  nav_container: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    padding: "10px 20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)", // Add box-shadow
    backdropFilter: "blur(5px)", // Add backdrop-filter (note: limited browser support)
    borderRadius: "24px", // Add border-radius,
  },
  nav: {
    width: "100%",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  navItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  icon: {
    height: "14px",
    width: "14px",
  },
  link: {
    textDecoration: "none",
  },
  activeLink: {
    textDecoration: "none",
  },
  text: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
  },
});

const NavBar = () => {
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 380px)" });
  const { theme } = useTheme(); // Access the theme
  const location = useLocation(); // Get current route
  const styles = useStyles(); // Get static styles
  // Function to check if the route is active
  const isActive = (path: string) => location.pathname === path;

  const openDrawer = () => setIsUserDrawerOpen(true);
  const closeDrawer = () => setIsUserDrawerOpen(false);

  return (
    <div className="mt-auto">
      <div
        className="flex justify-center gap-4 px-5 py-2.5 shadow-md rounded-[24px]"
        style={{
          backgroundColor: theme.background, // Apply dynamic background from theme
          fontSize: theme.fontSize.small,
          backdropFilter: "blur(5px)",
        }}
      >
        <div className={styles.nav}>
          {/* Home Link */}
          {/* <Link
            to="/home"
            className={`${styles.navItem} ${isActive("/") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/home") ? theme.iconColor : theme.textColor, // Apply dynamic text color
            }}
          >
            <PiHouseThin
              className={styles.icon}
              style={{
                fill: isActive("/home") ? theme.iconColor : "#fff", // Apply dynamic icon color based on active route
                height: "1.2rem",
                width: "2rem",
              }}
            />

            {!isSmallScreen && <span className={styles.text}>Home</span>}
          </Link> */}

          {/* Generate Link */}
          <Link
            to="/generate-equation"
            className={`${styles.navItem} ${isActive("/generate-equation") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/generate-equation") ? theme.iconColor : theme.textColor, // Apply dynamic text color
            }}
          >
            <PiNotePencilThin
              style={{
                height: "1.2rem",
                width: "2rem",
              }}
            />

            {!isSmallScreen && <span className={styles.text}>Generate</span>}
          </Link>

          <Link
            to="/ai-chat"
            className={`${styles.navItem} ${isActive("/ai-chat") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/ai-chat") ? theme.iconColor : theme.textColor,
            }}
          >
            <PiChatsCircleThin
              style={{
                fill: isActive("/ai-chat") ? theme.iconColor : "#fff",
                height: "1.2rem",
                width: "2rem",
              }}
            />

            {!isSmallScreen && <span className={styles.text}>Chat</span>}
          </Link>

          <Link
            to="/graph"
            className={`${styles.navItem} ${isActive("/graph") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/graph") ? theme.iconColor : theme.textColor,
            }}
          >
            <PiGraphThin
              style={{
                fill: isActive("/graph") ? theme.iconColor : "#fff",
                height: "1.2rem",
                width: "2rem",
              }}
            />
            {!isSmallScreen && <span className={styles.text}>Graph</span>}
          </Link>

          <Link
            to="/excel-from-sources"
            className={`${styles.navItem} ${isActive("/excel-from-sources") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/excel-from-sources") ? theme.iconColor : theme.textColor,
            }}
          >
            <PiFileXlsThin
              style={{
                fill: isActive("/excel-from-sources") ? theme.iconColor : "#fff",
                height: "1.2rem",
                width: "2rem",
              }}
            />
            {!isSmallScreen && <span className={styles.text}>Excel from Sources</span>}
          </Link>

          {/* <Link
            to="/lighthouse"
            className={`${styles.navItem} ${isActive("/lighthouse") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/lighthouse") ? theme.iconColor : theme.textColor,
            }}
          >
            <PiLighthouseThin
              style={{
                fill: isActive("/lighthouse") ? theme.iconColor : "#fff",
                height: "1.2rem",
                width: "2rem",
              }}
            />
            {!isSmallScreen && <span className={styles.text}>Lighthouse</span>}
          </Link> */}

          {/* <Link
            to="/windows"
            className={`${styles.navItem} ${isActive("/windows") ? styles.activeLink : styles.link}`}
            style={{
              color: isActive("/windows") ? theme.iconColor : theme.textColor,
            }}
          >
            <PiWindowsLogoLight
              style={{
                fill: isActive("/windows") ? theme.iconColor : "#fff",
                height: "1.2rem",
                width: "2rem",
              }}
            />
            {!isSmallScreen && <span className={styles.text}>Windows</span>}
          </Link> */}

          <Link to={null} onClick={openDrawer}>
            <PiUserThin
              style={{
                height: "1.2rem",
                width: "2rem",
              }}
            />
            {!isSmallScreen && <span className={styles.text}>Windows</span>}
          </Link>

          {/* User Drawer */}
          <UserDrawer isOpen={isUserDrawerOpen} onClose={closeDrawer} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
