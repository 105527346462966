export class RaisedError extends Error {
  detailedMessage?: string;
  constructor({ message, detailedMessage }: { message: string; detailedMessage: string }) {
    super(message);
    this.name = "RaisedError";
    Object.setPrototypeOf(this, RaisedError.prototype);
    this.detailedMessage = detailedMessage;
  }
}

export class APIError extends RaisedError {
  status: number;
  url: string;
  constructor({
    status,
    message,
    detailedMessage,
    url,
  }: {
    status: number;
    message: string;
    detailedMessage?: string;
    url: string;
  }) {
    super({ message, detailedMessage });
    Object.setPrototypeOf(this, APIError.prototype);
    this.name = "APIError";
    this.status = status;
    this.url = url;
  }
}

export class NotFoundError extends APIError {
  constructor({ message, url }: { message: string; url: string }) {
    const detailedMessage = `The requested URL ${url} was not found on this server.`;
    super({ status: 404, message, detailedMessage, url });
    this.name = "NotFoundError";
  }
}

export class EmailExistError extends APIError {
  constructor({ message, url }: { message: string; url: string }) {
    const detailedMessage = `The email already exist.`;
    super({ status: 409, message, detailedMessage, url });
    this.name = "EmailExistError";
  }
}
