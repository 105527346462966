import { useMutation } from "@tanstack/react-query";
import { useFetchWithAuth } from "./useFetchWithAuth";
import { apiPost } from "@/utils/fetch";
import { GRAPH_GENERATE_ENDPOINT, LLM_MODELS } from "@/constants";
import { ExcelChartTypeAPI } from "@/types/graph";

interface GenerateGraphInput {
  selectedValues: string[][][];
  range: string[];
  userPrompt: string;
  hasHeaders: boolean;
}

export interface GenerateGraphResponse {
  chart: {
    type: ExcelChartTypeAPI;
    reason: string;
    data: {
      range: string[];
      categories: string[];
    };
    title: {
      text: "string";
    };
    axes: {
      x: {
        title: "string";
      };
      y: {
        title: "string";
      };
    };
  };
}

export const useGenerateGraph = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  const mutation = useMutation<GenerateGraphResponse, Error, GenerateGraphInput>({
    mutationFn: async ({ selectedValues, range, userPrompt, hasHeaders }) => {
      return fetchWithAuth(async () => {
        const response = await apiPost<GenerateGraphResponse>(GRAPH_GENERATE_ENDPOINT, {
          userPrompt,
          tableValues: selectedValues,
          range,
          model: LLM_MODELS.DEFAULT,
          hasHeaders,
        });
        return response;
      });
    },
  });

  return mutation;
};
