export const GENERATE_EQUATION_ROUTE = "/generate-equation";
export const LOGIN_SIGNUP_ROUTE = "/";
export const HOME_ROUTE = "/home";
export const AI_CHAT_ROUTE = "/ai-chat";
export const GENERATE_GRAPH_ROUTE = "/graph";
export const EXCEL_FROM_SOURCES_ROUTE = "/excel-from-sources";
export const VERIFY_EMAIL_ROUTE = "/verify-email";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const CHANGE_PASSWORD_ROUTE = "/change-password";
export const USER_CHANGE_PASSWORD_ROUTE = "/user-change-password";
export const VIEW_PLANS_ROUTE = "/plans";
