import { EQUATION_GENERATE_ENDPOINT, LLM_MODELS } from "@/constants";
import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";
import { useFetchWithAuth } from "./useFetchWithAuth";
import { getDefaultRange, getSelectedValues } from "@/taskpane/taskpane";

interface GenerateEquationInput {
  text: string;
}

interface GenerateEquationResponse {
  llmResponse: string;
}

export const useGenerateEquation = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  const mutation = useMutation<GenerateEquationResponse, Error, GenerateEquationInput>({
    mutationFn: async ({ text }) => {
      const range = await getDefaultRange();
      const rangePart = range.split("!").pop() || "";
      const currentRange = rangePart.split(",").map((r) => r.trim());
      const values = await getSelectedValues(currentRange);

      return fetchWithAuth(async () => {
        const response = await apiPost<GenerateEquationResponse>(EQUATION_GENERATE_ENDPOINT, {
          userPrompt: text,
          model: LLM_MODELS.DEFAULT,
          range: currentRange,
          tableValues: values,
        });
        return response;
      });
    },
  });

  return mutation;
};
