/* global */

import { useMutation } from "@tanstack/react-query";
import { LLM_MODELS } from "@/constants";
import { apiPostStreaming } from "@/utils/fetch";
import { useFetchWithAuth } from "./useFetchWithAuth";

interface SendMessageInput {
  conversation_id: number;
  message: string;
  model: string;
}

export const useSendMessage = (onMessage: (message: string) => void, onStream: (isStreaming: boolean) => void) => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useMutation<string, Error, SendMessageInput>({
    mutationFn: async ({ message, conversation_id }) => {
      return fetchWithAuth(async () => {
        // Use the apiFetchWithStreaming function to initiate the request
        const url = `/api/conversations/stream/${conversation_id}`;
        const response = await apiPostStreaming(url, { message: message, model: LLM_MODELS.DEFAULT });

        // Handle streaming response
        const reader = response.getReader();
        const decoder = new TextDecoder("utf-8");
        let done: boolean = false;
        let newContent = "";
        let buffer = "";

        // Read the stream until it is done
        while (!done) {
          const { value, done: isDone } = await reader.read();
          onStream(true);
          done = isDone;

          // Decode the stream value into a string
          const chunk = decoder.decode(value || new Uint8Array(), { stream: true });
          buffer += chunk;

          // Extract content from the streamed response
          const contentMatches = buffer.match(/"content":\s*"([^"]*)"/g);
          if (contentMatches) {
            contentMatches.forEach((match) => {
              const content = match.replace(/"content":\s*"/, "").replace(/"$/, "");
              newContent += content;
              onMessage(newContent); // Call the callback with the updated message
            });
            buffer = ""; // Clear buffer after processing
          }
        }
        onStream(false);
        return newContent;
      });
    },
  });
};
