import { Button, Image } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import LoadingCircle from "./LoadingCircle";

import SheetShark from "@/assets/sheetshark_logo_main_stacked_white.svg";
import { useTheme } from "@/providers/ThemeProvider";
import { useResetPassword } from "@/hooks/useResetPassword";
import { useNavigate } from "react-router-dom";
import { LOGIN_SIGNUP_ROUTE } from "@/constants";

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [inputEmail, setInputEmail] = useState<string>("");
  const [cooldown, setCooldown] = useState<number>(0);

  const { mutate: resetPassword, isPending } = useResetPassword();

  useEffect(() => {
    let timer: number;
    if (cooldown > 0) {
      timer = window.setTimeout(() => setCooldown((prev) => prev - 1), 1000);
    }
    return () => window.clearTimeout(timer);
  }, [cooldown]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (cooldown === 0 && inputEmail.trim() !== "") {
      resetPassword({ email: inputEmail, setCooldown });
    }
  };

  const handleBackToLogin = () => {
    navigate(LOGIN_SIGNUP_ROUTE);
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full gap-4 max-w-sm px-4">
        <div className="flex flex-col items-center">
          <Image src={SheetShark} alt="SheetShark Logo" height={140} width={140} />
          <h2 style={{ color: theme.textColor }} className="text-sm">
            Forgot Password
          </h2>
          <h3 className="text-xs text-gray-300 mt-1">Type your email to recover password</h3>
        </div>

        <input
          onChange={(e) => setInputEmail(e.target.value)}
          disabled={isPending}
          className="w-full px-5 py-2 rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none placeholder-white"
          placeholder="Email"
          type="email"
          value={inputEmail}
          required
        />

        <Button
          appearance="primary"
          disabled={isPending || inputEmail.trim() === "" || cooldown > 0}
          size="large"
          type="submit"
          style={{
            width: "100%",
            color: "white",
            padding: "0.6rem 1rem",
            borderRadius: "8px",
            background: "#080808",
            fontSize: "12px",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isPending ? (
            <LoadingCircle width="16px" height="16px" />
          ) : cooldown > 0 ? (
            `Resend in ${cooldown}s`
          ) : (
            "Reset Password"
          )}
        </Button>
        <button type="button" onClick={handleBackToLogin} className="text-xs hover:underline text-white">
          Go back to login
        </button>
      </form>
    </div>
  );
};
