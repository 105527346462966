import React from "react";
import { Image } from "@fluentui/react-components";

import SheetShark from "@/assets/sheetshark_icon_main_white.svg";
import NavBar from "../components/NavBar";
import { useTheme } from "@/providers/ThemeProvider";
import { useScreenSize } from "@/hooks/useScreenSize";

export const MainLayout = ({ children, headerTitle }: { children: React.ReactNode; headerTitle: string }) => {
  const { isSmallScreen } = useScreenSize();
  const { theme } = useTheme();
  return (
    <div className={`text-white min-h-screen flex flex-col ${isSmallScreen ? "p-2.5" : "px-5 py-2.5"}`}>
      <div className="w-full flex items-center justify-between">
        {/* Home text centered */}
        <span className="flex-1 text-left font-bold" style={{ color: theme.textColor, fontSize: theme.fontSize.large }}>
          {headerTitle}
        </span>

        {/* Logo and Pro aligned to the right */}
        <div className="flex flex-col items-center">
          <Image src={SheetShark} alt="SheetShark Logo" width={50} />
          <span style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "10px" }}>Pro</span>
        </div>
      </div>
      <div className="flex-1 flex flex-col w-full my-2.5">{children}</div>
      <NavBar />
    </div>
  );
};
