import { useQuery } from "@tanstack/react-query";
import { apiGet } from "@/utils/fetch";
import { USER_INFO_ENDPOINT } from "@/constants";

interface UserInfoResponse {
  id: number;
  email: string;
  verificationStatus: string;
  verifiedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  requiredResetPassword: boolean;
}

export const getUserInfo = async (): Promise<UserInfoResponse> => {
  return apiGet<UserInfoResponse>(USER_INFO_ENDPOINT);
};

export const useGetUserInfo = () => {
  return useQuery<UserInfoResponse, Error>({
    queryKey: ["userInfo"],
    queryFn: getUserInfo,
    staleTime: 0,
    refetchOnWindowFocus: true,
    retry: 1, // Retry once if the request fails
  });
};
