/* global Excel */

import React, { useState } from "react";

import { MainLayout } from "@/taskpane/layouts/MainLayout";
import { GlassBackground } from "@/taskpane/components/GlassBackground";

import ExcelFileIcon from "@/assets/ExcelFileIcon.svg";
import { BsFiletypeCsv, BsFiletypeXlsx } from "react-icons/bs";
import toast from "react-hot-toast";
import { Button, Image } from "@fluentui/react-components";
import LoadingCircle from "@/taskpane/components/LoadingCircle";
import { useExcelSources } from "@/hooks/useExcelSources";
import Step2Processing from "@/taskpane/components/steps/Step2Processing";
import Step3SheetSelection from "@/taskpane/components/steps/Step3SheetSelection";
import Step4Finished from "@/taskpane/components/steps/Step4Finished";
import { appendFormulaToOfficeWorksheet, copySheetsToCurrentWorkbook } from "@/utils/excelFromSources";
import {
  deleteSheetsFromWorkbook,
  getDefaultRangeForSheet,
  getValuesFromSheetByName,
  insertChartOnSheet,
  isDesktopExcel,
} from "../taskpane";

export const ExcelFromSources = () => {
  const [step, setStep] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const [userPrompt, setUserPrompt] = useState("");
  const [processedFileUrl, setProcessedFileUrl] = useState<string | null>(null);
  const [originalFilename, setOriginalFilename] = useState<string | null>(null);
  const [foundSheets, setFoundSheets] = useState<string[]>([]);
  const [workbook, setWorkbook] = useState<Excel.Workbook | null>(null);
  const [selectedSheetName, setSelectedSheetName] = useState<string | null>(null);
  const [originalSheetsName, setOriginalSheetsName] = useState<string[]>([]);

  const { mutate: excelSources, isPending: isProcessing } = useExcelSources();

  const handleGoAgain = () => {
    setStep(1);
    setFile(null);
    setProcessedFileUrl(null);
    setWorkbook(null);
    setSelectedSheetName(null);
    setFoundSheets([]);
    setOriginalFilename(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (
      selectedFile &&
      (selectedFile.type === "text/csv" ||
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setFile(selectedFile);
    } else {
      setFile(null);
      toast.error("Please upload a valid CSV or XLS file.");
    }
  };

  const handleSheetSelection = async (sheetName: string) => {
    try {
      if (!workbook) {
        throw new Error("Workbook is not loaded.");
      }

      setSelectedSheetName(sheetName); //Save the selected sheet name

      const range = await getDefaultRangeForSheet(sheetName);
      const values = await getValuesFromSheetByName(sheetName, [range]);
      console.log(range);
      console.log(values);

      excelSources(
        { selectedValues: values, range: [range], userPrompt },
        {
          onSuccess: async (response) => {
            if (response.operation === "EQUATION") {
              const label = response.label;
              const formula = response.data.llmResponse;
              const { formulaCellAddress } = await appendFormulaToOfficeWorksheet(sheetName, label, formula);

              console.log(`Appended to  ${formulaCellAddress}`);
              toast.success(`Formula is inserted in  ${formulaCellAddress}`, { duration: 7000 });
              setStep(4);
            } else if (response.operation === "GRAPH") {
              toast.success(`Successfully inserted a chart on ${sheetName}`);
              await insertChartOnSheet(response.data.chart, sheetName);
              setStep(4);
            } else {
              setStep(1);
              console.log("The prompt is invalid or the file contains unsupported data and cannot be processed.");
              toast.error("The prompt is invalid or the file contains unsupported data and cannot be processed.", {
                duration: 7000,
              });
              await deleteSheetsFromWorkbook(foundSheets); //Delete the copied worksheets for cleanup
            }
          },
          onError: async (error) => {
            setStep(1);
            toast.error("Something went wrong.");
            console.log("Failed to process: ", error);
            await deleteSheetsFromWorkbook(foundSheets); //Delete the copied worksheets for cleanup
          },
        }
      );

      console.log(`Selected Sheet: ${sheetName}`);
    } catch (error) {
      console.error("Error processing selected sheet:", error);
      toast.error("An error occurred while processing the selected sheet.");
      setStep(1);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isDesktopExcel()) console.log("Running in Desktop Excel.");

    if (!file) {
      toast.error("Please upload a file before submitting.");
      return;
    }

    const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
    setOriginalFilename(filenameWithoutExtension);

    if (!userPrompt.trim()) {
      toast.error("Please enter a prompt before submitting.");
      return;
    }

    await Excel.run(async (context) => {
      try {
        setWorkbook(context.workbook);

        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          console.log("Processing Excel file...");

          // Capture initial worksheet names
          const worksheets = context.workbook.worksheets;
          worksheets.load("items/name");
          await context.sync();

          const initialSheetNames = worksheets.items.map((sheet) => sheet.name);

          setOriginalSheetsName(initialSheetNames);
          console.log("initial sheets", originalSheetsName);

          // Copy sheets to the workbook
          await copySheetsToCurrentWorkbook(file, true);

          // Reload worksheets to identify newly added ones
          worksheets.load("items/name");
          await context.sync();

          // Filter for newly added sheets
          const newSheetNames = worksheets.items
            .map((sheet) => sheet.name)
            .filter((name) => !initialSheetNames.includes(name) && name.startsWith("sheetshark-"));

          console.log("Filtered new sheet names:", newSheetNames);
          setFoundSheets(newSheetNames);

          if (newSheetNames.length > 1) {
            setStep(3);
          } else if (newSheetNames.length === 1) {
            const range = await getDefaultRangeForSheet(newSheetNames[0]);
            const values = await getValuesFromSheetByName(newSheetNames[0], [range]);

            console.log("Default Range:", range);
            console.log("Selected Values:", values);

            // Process Excel sources
            excelSources(
              { selectedValues: values, range: [range], userPrompt },
              {
                onSuccess: async (response) => {
                  if (response.operation === "EQUATION") {
                    const label = response.label;
                    const formula = response.data.llmResponse;
                    const { formulaCellAddress } = await appendFormulaToOfficeWorksheet(
                      newSheetNames[0],
                      label,
                      formula
                    );

                    console.log(`Appended formula to ${formulaCellAddress}`);
                    toast.success(`Formula inserted in ${formulaCellAddress}`, { duration: 7000 });
                    setStep(4);
                  } else if (response.operation === "GRAPH") {
                    console.log("Inserting graph...");
                    toast.success(`Successfully inserted a chart on ${newSheetNames[0]}`);
                    await insertChartOnSheet(response.data.chart, newSheetNames[0]);
                    setStep(4);
                  } else {
                    console.error("Unsupported prompt or file data.");
                    toast.error("Unsupported prompt or file data.", { duration: 7000 });
                    await deleteSheetsFromWorkbook(newSheetNames); // Cleanup
                    setStep(1);
                  }
                },
                onError: async (error) => {
                  console.error("Processing error:", error);
                  toast.error("Something went wrong.", { duration: 7000 });
                  await deleteSheetsFromWorkbook(newSheetNames); // Cleanup
                  setStep(1);
                },
              }
            );
          } else {
            console.error("No valid sheets added.");
            toast.error("No valid sheets were added to the workbook.", { duration: 7000 });
            setStep(1);
          }
        } else if (file.type === "text/csv") {
          const text = await file.text(); // Read CSV content
          const rows = text.split("\n").map((row) => row.split(",")); // Parse CSV into rows and columns

          // Capture initial worksheet names BEFORE adding the new sheet
          const worksheets = context.workbook.worksheets;
          worksheets.load("items/name");
          await context.sync();

          const initialSheetNames = worksheets.items.map((sheet) => sheet.name);
          setOriginalSheetsName(initialSheetNames);
          console.log("Initial sheets:", initialSheetNames);

          // Add a new worksheet for CSV content
          const csvWorksheet = context.workbook.worksheets.add("sheetshark-csv");
          setSelectedSheetName("sheetshark-csv");

          // Populate the worksheet with the CSV data
          rows.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
              csvWorksheet.getCell(rowIndex, colIndex).values = [[cell]];
            });
          });

          await context.sync(); // Sync changes before hiding the worksheet

          // Hide the CSV worksheet
          csvWorksheet.visibility = Excel.SheetVisibility.hidden;
          await context.sync();

          // Reload worksheets to identify newly added ones
          worksheets.load("items/name");
          await context.sync();

          // Filter for newly added sheets
          const newSheetNames = worksheets.items
            .map((sheet) => sheet.name)
            .filter((name) => !initialSheetNames.includes(name) && name.startsWith("sheetshark-"));

          console.log("Filtered new sheet names:", newSheetNames);
          setFoundSheets(newSheetNames);

          // Continue with processing logic
          const range = await getDefaultRangeForSheet(newSheetNames[0]);
          const values = await getValuesFromSheetByName(newSheetNames[0], [range]);

          console.log("Default Range:", range);
          console.log("Selected Values:", values);

          // Process Excel sources
          excelSources(
            { selectedValues: values, range: [range], userPrompt },
            {
              onSuccess: async (response) => {
                if (response.operation === "EQUATION") {
                  const label = response.label;
                  const formula = response.data.llmResponse;
                  const { formulaCellAddress } = await appendFormulaToOfficeWorksheet(newSheetNames[0], label, formula);

                  console.log(`Appended formula to ${formulaCellAddress}`);
                  toast.success(`Formula inserted in ${formulaCellAddress}`, { duration: 7000 });
                  setStep(4);
                } else if (response.operation === "GRAPH") {
                  console.log("Inserting graph...");
                  toast.success(`Successfully inserted a chart on ${newSheetNames[0]}`);
                  await insertChartOnSheet(response.data.chart, newSheetNames[0]);
                  setStep(4);
                } else {
                  console.error("Unsupported prompt or file data.");
                  toast.error("Unsupported prompt or file data.", { duration: 7000 });
                  await deleteSheetsFromWorkbook(newSheetNames); // Cleanup
                  setStep(1);
                }
              },
              onError: async (error) => {
                console.error("Processing error:", error);
                toast.error("Something went wrong.", { duration: 7000 });
                await deleteSheetsFromWorkbook(newSheetNames); // Cleanup
                setStep(1);
              },
            }
          );
        } else {
          toast.error("Invalid file type. Please upload a valid Excel (.xlsx) or CSV file.");
          setStep(1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        toast.error("An unexpected error occurred while processing your file.", { duration: 7000 });
        setStep(1);
      }
    });
  };

  // const accessHiddenSheets = async () => {
  //   try {
  //     await Excel.run(async (context) => {
  //       const workbook = context.workbook;
  //       const worksheets = workbook.worksheets;

  //       // Load all worksheets with their visibility status
  //       worksheets.load("items/name, items/visibility");
  //       await context.sync();

  //       // Filter to find hidden sheets
  //       const hiddenSheets = worksheets.items.filter((sheet) => sheet.visibility === Excel.SheetVisibility.hidden);

  //       if (hiddenSheets.length === 0) {
  //         console.log("No hidden sheets found.");
  //         toast.error("No hidden sheets found.");
  //         return;
  //       }

  //       console.log(
  //         "Hidden Sheets:",
  //         hiddenSheets.map((sheet) => sheet.name)
  //       );

  //       // Example: Access and unhide a hidden sheet
  //       hiddenSheets.forEach((sheet) => {
  //         console.log(`Accessing hidden sheet: ${sheet.name}`);
  //         sheet.visibility = Excel.SheetVisibility.visible; // Make the sheet visible
  //       });

  //       await context.sync();

  //       toast.success("Hidden sheets have been made visible!");
  //     });
  //   } catch (error) {
  //     console.error("Error accessing hidden sheets:", error);
  //     toast.error("An error occurred while accessing hidden sheets.");
  //   }
  // };

  return (
    <MainLayout headerTitle="Excel From Sources">
      {step === 1 && !isProcessing && (
        <GlassBackground>
          <form onSubmit={handleSubmit} className="flex flex-col gap-2 h-full w-full">
            <div className="flex flex-col gap-2 h-full">
              <span className="font-bold text-lg text-center">File Processor</span>
              <input
                type="file"
                accept=".csv,.xls,.xlsx"
                onChange={handleFileChange}
                className="sr-only"
                id="file-upload"
              />
              <label
                htmlFor="file-upload"
                className="group flex flex-col items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer bg-[rgba(217,217,217,0.3)] hover:bg-[rgba(217,217,217,0.5)]"
              >
                <div className="flex flex-col items-center justify-center">
                  {!file ? (
                    <div className="flex flex-col items-center gap-2">
                      <Image
                        src={ExcelFileIcon}
                        alt="Excel File Icon"
                        className="group-hover:scale-125 transition-transform duration-300 ease-in-out"
                      />
                      <p className="text-sm">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p className="text-xs">CSV or XLSX (MAX. 10MB)</p>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-2">
                      {file?.type === "text/csv" ? (
                        <BsFiletypeCsv className="size-14" />
                      ) : file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                        <BsFiletypeXlsx className="size-14" />
                      ) : (
                        <p className="text-xs text-red-500">Unsupported File</p>
                      )}
                      <p className="text-xs text-white/85 text-center px-2">{file.name}</p>
                    </div>
                  )}
                </div>
              </label>
            </div>

            <textarea
              placeholder="Enter your prompt here (equations and graphs)"
              value={userPrompt}
              onChange={(e) => setUserPrompt(e.target.value)}
              className="inputField rounded-lg p-2.5 text-white text-sm shadow-md border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] outline-none resize-y overflow-auto"
              rows={6}
            />

            <Button
              appearance="primary"
              disabled={false}
              size="large"
              type="submit"
              style={{
                width: "100%",
                padding: "0.6rem 2rem",
                borderRadius: "24px",
                background: "#080808",
                fontSize: "12px",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isProcessing ? <LoadingCircle width="16px" height="16px" /> : "Submit"}
            </Button>
            {/* <button onClick={accessHiddenSheets}>Access hidden sheet</button> */}
          </form>
        </GlassBackground>
      )}
      {isProcessing && <Step2Processing />}
      {step === 4 && (
        <Step4Finished
          addedSheetNames={foundSheets}
          originalSheetsName={originalSheetsName}
          selectedSheetName={selectedSheetName}
          fileName={originalFilename}
          processedFileUrl={processedFileUrl}
          onGoAgain={handleGoAgain}
        />
      )}
      {step === 3 && workbook && !isProcessing && (
        <Step3SheetSelection sheets={foundSheets} onSelect={(sheetName) => handleSheetSelection(sheetName)} />
      )}
    </MainLayout>
  );
};
