import { useNavigate } from "react-router-dom";
import { apiPost } from "@/utils/fetch";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { CHANGE_PASSWORD_ROUTE, GENERATE_EQUATION_ROUTE, VERIFY_EMAIL_ROUTE } from "@/constants";

interface UserInfoResponse {
  id: number;
  email: string;
  verificationStatus: string;
  verifiedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface LoginInput {
  email: string;
  password: string;
}

interface LoginResponse {
  ok: boolean;
  userInfo: UserInfoResponse;
  requiredPasswordChange: boolean;
}

async function loginWithEmail(email: string, password: string): Promise<LoginResponse> {
  return apiPost<LoginResponse>("/api/auth/login-with-email", { email, password });
}

export const useLogin = () => {
  const navigate = useNavigate();

  return useMutation<LoginResponse, Error, LoginInput>({
    mutationFn: ({ email, password }) => loginWithEmail(email, password),
    onSuccess: async ({ userInfo, requiredPasswordChange }) => {
      try {
        if (requiredPasswordChange) {
          navigate(CHANGE_PASSWORD_ROUTE);
        }
        // Next, check verification status
        else if (userInfo.verificationStatus === "APPROVED") {
          navigate(GENERATE_EQUATION_ROUTE);
        } else if (userInfo.verificationStatus === "PENDING") {
          navigate(VERIFY_EMAIL_ROUTE);
        }
      } catch (error) {
        // Handle error fetching user info
        toast.error("Error verifying user status. Please try again.");
      }
    },
  });
};
