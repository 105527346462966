import React from "react";

interface LoadingCircleProps {
  width: string;
  height: string;
}

const LoadingCircle = ({ width, height }: LoadingCircleProps) => {
  return (
    <>
      <svg
        style={{
          width: width,
          height: height,
          animation: "spin 1s linear infinite",
        }}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="#ffffff"
          strokeWidth="4"
          strokeDasharray="31.4"
          strokeDashoffset="0"
          strokeLinecap="round"
        />
      </svg>
      <style>{`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default LoadingCircle;
