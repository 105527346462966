import { RESEND_VERIFY_EMAIL_ENDPOINT } from "@/constants";
import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

interface ResendVerificationEmailResponse {
  ok: boolean;
  message: string;
}

const resendVerificationEmail = async (): Promise<ResendVerificationEmailResponse> => {
  return apiPost<ResendVerificationEmailResponse>(RESEND_VERIFY_EMAIL_ENDPOINT, {});
};

export const useResendVerificationEmail = () => {
  return useMutation({
    mutationFn: () => resendVerificationEmail(),
    onSuccess: (data) => {
      if (data.ok) {
        toast.success(
          "Verification email resent successfully. Please check your inbox and follow the instructions to verify your account.",
          {
            duration: 7000,
          }
        );
      }
    },
    onError: () => {
      toast.error("Failed to resend the verification email. Please try again later.");
    },
  });
};
