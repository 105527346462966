/* global  */

import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTheme } from "@/providers/ThemeProvider";
import { Image } from "@fluentui/react-components";

import SheetShark from "@/assets/sheetshark_logo_main_stacked_white.svg";
import { useSignUp } from "@/hooks/useSignUp";
import { useLogin } from "@/hooks/useLogin";
import LoadingCircle from "@/taskpane/components/LoadingCircle";
import { useAuthSession } from "@/hooks/useAuthSession";
import { useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD_ROUTE, FORGOT_PASSWORD_ROUTE, GENERATE_EQUATION_ROUTE, VERIFY_EMAIL_ROUTE } from "@/constants";
import { useGetUserInfo } from "@/hooks/useGetUserInfo";

type LoginFormInputs = {
  email: string;
  password: string;
};

type ErrorState = {
  message: string;
  hasError: boolean;
};

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [error, setError] = useState<ErrorState>({ message: "", hasError: false });
  const { mutate: signUpMutate, isPending: isSignUpPending } = useSignUp();
  const { mutate: loginMutate, isPending: isLoginPending } = useLogin();
  const { data: userInfo, isSuccess: getUserInfoFinish } = useGetUserInfo();
  const { data: authSession, isPending: isCheckingSession, isSuccess: isCheckingSessionFinish } = useAuthSession();

  useEffect(() => {
    if (isCheckingSessionFinish && authSession.ok) {
      if (getUserInfoFinish) {
        if (userInfo.requiredResetPassword) {
          navigate(CHANGE_PASSWORD_ROUTE);
          return;
        }
        if (userInfo.verificationStatus === "APPROVED") {
          navigate(GENERATE_EQUATION_ROUTE);
        } else {
          navigate(VERIFY_EMAIL_ROUTE);
        }
      }
    }
  }, [authSession, isCheckingSessionFinish, getUserInfoFinish, userInfo, navigate]);

  // Handles form submission for login and signup
  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    const { email, password } = data;

    if (isSigningUp) {
      signUpMutate(
        { email, password },
        {
          onError: (error: any) => {
            const errorMessage = error.message || "An unexpected error occurred.";
            setError({ message: errorMessage, hasError: true });
          },
        }
      );
    } else {
      loginMutate(
        { email, password },
        {
          onError: (error: any) => {
            const errorMessage = error.message || "An unexpected error occurred.";
            setError({ message: errorMessage, hasError: true });
          },
        }
      );
    }
  };

  const handleToggleAuthMode = () => {
    setError({ message: "", hasError: false });
    setIsSigningUp((prev) => !prev);
    //Reset the inputs
    reset();
  };

  const handleForgotPassword = () => {
    navigate(FORGOT_PASSWORD_ROUTE);
  };

  return (
    <div className="h-screen flex justify-center items-center w-full">
      <div>
        <div className="flex flex-col items-center">
          <Image src={SheetShark} alt="SheetShark Logo" height={140} width={140} />
          <h2 style={{ color: theme.textColor }}>{isSigningUp ? "Sign Up" : "Log in"}</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-1 p-4 box-border max-w-[420px] w-full">
          <div className="flex flex-col w-full max-w-[400px]">
            <label className="mb-2 text-white" style={{ fontSize: theme.fontSize.medium }}>
              Email
            </label>
            <input
              disabled={isSignUpPending || isLoginPending || isCheckingSession}
              className="px-5 py-2 rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none"
              type="email"
              {...register("email", { required: "Email is required" })}
            />
            {/* Error message container with reserved space */}
            <p className="text-red-500 min-h-[20px] mt-1">{errors.email ? errors.email.message : " "}</p>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "400px" }}>
            <div className="flex justify-between w-full items-center mb-2">
              <label style={{ color: "white", fontSize: theme.fontSize.medium }}>Password</label>
              <p className="text-red-500 text-xs">{errors.password ? errors.password.message : " "}</p>
            </div>
            <input
              disabled={isSignUpPending || isLoginPending || isCheckingSession}
              className="px-5 py-2 rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none"
              type="password"
              {...register("password", { required: "Password is required" })}
            />
            <button
              type="button"
              onClick={handleForgotPassword}
              className="text-xs text-right mt-1 hover:underline text-white"
            >
              Forgot Password?
            </button>
          </div>

          <button
            type="submit"
            disabled={isSignUpPending || isLoginPending || isCheckingSession}
            className="mt-4 px-5 py-2.5 bg-[#04346F] rounded-2xl outline-none cursor-pointer flex items-center justify-center h-[40px] min-w-[100px]"
            style={{ fontSize: theme.fontSize.medium, color: theme.textColor }}
          >
            {isSignUpPending || isLoginPending || isCheckingSession ? (
              <LoadingCircle width="14px" height="14px" />
            ) : isSigningUp ? (
              "Sign Up"
            ) : (
              "Log in"
            )}
          </button>
        </form>
        {error.hasError && (
          <p
            className="text-[#CC3333] text-center w-full max-w-[400px] min-h-[12px] break-words box-border whitespace-normal"
            style={{
              fontSize: theme.fontSize.small,
            }}
          >
            {error.message}
          </p>
        )}
        <p style={{ color: theme.textColor, fontSize: theme.fontSize.small, textAlign: "center" }}>
          {!isSigningUp ? "Don't have an account?" : "Already have an account?"}{" "}
          <strong style={{ cursor: "pointer" }} onClick={handleToggleAuthMode}>
            {!isSigningUp ? "Sign Up" : "Log in"}
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Login;
