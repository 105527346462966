/* global URL */

export function generateFullUrl(baseUrl: string, queryParams: Record<string, string | number>) {
  const url = new URL(baseUrl);
  Object.entries(queryParams).forEach(([key, value]) => {
    url.searchParams.append(key, value.toString());
  });
  return url.toString();
}

export function generateDialogMessageForError(error: any) {
  if (typeof error === "string") {
    return {
      name: "Error",
      message: error,
    };
  } else if (error instanceof Error) {
    // TODO: figure out how to make instanceof checks work for subclasses of Error
    return {
      name: error.name || "Error",
      message: error.message,
      stack: error.stack,
      //@ts-ignore
      detailedMessage: error.detailedMessage,
      //@ts-ignore
      status: error.status,
    };
  } else {
    throw new Error("Unknown error type");
  }
}
