import { LOGIN_SIGNUP_ROUTE } from "@/constants/frontendRoutes";
import { apiPost } from "@/utils/fetch";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

interface LogoutResponse {
  message: string;
}

// Function to sign up a user with email
async function logout(): Promise<LogoutResponse> {
  const response = apiPost<LogoutResponse>("/api/auth/logout", {});

  return response;
}

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation<LogoutResponse, Error>({
    mutationFn: logout,
    onSuccess: () => {
      navigate(LOGIN_SIGNUP_ROUTE);
    },
  });
};
