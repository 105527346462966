import React, { createContext, useContext, useState, ReactNode } from "react";
import { ThemeType, themes } from "@/theme/theme-config";

type ThemeContextType = {
  theme: (typeof themes)[ThemeType]; // Store the selected theme's properties directly
  setTheme: (theme: ThemeType) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeType, setThemeType] = useState<ThemeType>("dark"); // Default theme

  // Directly provide the theme object for the selected theme type
  const theme = themes[themeType];

  return <ThemeContext.Provider value={{ theme, setTheme: setThemeType }}>{children}</ThemeContext.Provider>;
};
