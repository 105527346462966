/* global Office */
import * as React from "react";
import { MainLayout } from "../layouts/MainLayout";
import { Image, Drawer, DrawerHeader, DrawerBody, Dialog, DialogTrigger } from "@fluentui/react-components";
import { useTheme } from "@/providers/ThemeProvider";
import { GlassBackground } from "./GlassBackground";
import SendIcon from "@/assets/SendIcon.svg";
import { useFetchConversations } from "@/hooks/useFetchConversations";
import { useFetchConversationMessages } from "@/hooks/useFetchConversationMessages";
import { useGenerateConversation } from "@/hooks/useGenerateConversation";
import { LLM_MODELS } from "@/constants";
import { useSendMessage } from "@/hooks/useSendMessage";
import LoadingCircle from "./LoadingCircle";
import { BiConversation } from "react-icons/bi";
import { MdClose, MdDelete } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import MemoizedRenderChatBubble from "./RenderChatBubble";
import { useDeleteConversation } from "@/hooks/useDeleteConversation";
import LoadingIndicator from "./LoadingResponse";
import toast from "react-hot-toast";
import { CustomDialogSurface } from "./CustomDialogSurface";

// Define a Message interface
interface Message {
  id: number;
  content: string;
  role: string; // HUMAN or AI, SYSTEM
  conversationId: number;
  failed?: boolean;
}

export const AIChat = () => {
  const { theme } = useTheme();
  const [userInput, setUserInput] = React.useState("");
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const {
    data: conversations,
    isLoading: isLoadingConversations,
    isFetching: isFetchingConversations,
    isSuccess: isSuccessFetchConversations,
  } = useFetchConversations();
  const [selectedConversationId, setSelectedConversationId] = React.useState<number | null>(null);
  const [chatHistory, setChatHistory] = React.useState<Message[]>([]);
  const [isStreaming, setIsStreaming] = React.useState<boolean>(false);
  const [lastMessage, setLastMessage] = React.useState<string | null>(null);
  const { mutate: generateConversationMutation } = useGenerateConversation(setLastMessage, setIsStreaming);
  const { mutate: sendMessageMutate } = useSendMessage(setLastMessage, setIsStreaming);
  const { mutate: deleteConversation, isPending: isDeletingConversation } = useDeleteConversation();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [conversationToDelete, setConversationToDelete] = React.useState<number | null>(null);
  const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);

  const [isCreatingNewConversation, setIsCreatingNewConversation] = React.useState(false);

  // New state for managing new conversation creation
  React.useEffect(() => {
    const lastConversationId = Office.context.document.settings.get("lastConversationId");
    if (isSuccessFetchConversations && !isLoadingConversations) {
      if (conversations && conversations.length > 0) {
        // Set the first conversation as selected if conversations exist
        if (selectedConversationId === null) {
          if (!isCreatingNewConversation) {
            setSelectedConversationId(lastConversationId ?? conversations[0].id);
          }

          if (lastConversationId) {
            if (chatHistory.length === 0) {
              setSelectedConversationId(conversations[0].id);
            }
          }
        }
      } else {
        // If no conversations exist, set the default AI message
        setChatHistory([
          {
            id: Date.now(),
            content: "Hello! How can I assist you with Excel today?",
            role: "AI",
            conversationId: 0, // No conversationId yet
          },
        ]);
      }
    }
  }, [
    isSuccessFetchConversations,
    conversations,
    isLoadingConversations,
    selectedConversationId,
    isCreatingNewConversation,
  ]);

  const {
    data: messages,
    isLoading: isLoadingMessages,
    error: messagesError,
  } = useFetchConversationMessages(selectedConversationId || null);

  React.useEffect(() => {
    if (isLoadingMessages) {
      setChatHistory([]); // Clear chat history when loading new messages
    }
  }, [isLoadingMessages]);

  React.useEffect(() => {
    if (messages) {
      setChatHistory(messages);
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (isLoadingResponse || isStreaming) return;

    setIsStreaming(false);
    if (userInput.trim() === "") return;

    const userMessageId = Date.now();
    const messageToSend = userInput;
    setUserInput("");

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }

    setLastMessage(null);
    setIsLoadingResponse(true);

    const userMessage = {
      id: userMessageId,
      content: messageToSend,
      role: "HUMAN",
      conversationId: selectedConversationId ?? 0, // Temporary conversationId 0 if no conversation exists
    };

    setChatHistory((prev) => [...prev, userMessage]);

    if (selectedConversationId === null) {
      // Generate a new conversation if none exists
      generateConversationMutation(
        {
          message: messageToSend,
          model: LLM_MODELS.DEFAULT,
          aiMessage: "Hello! How can I assist you with Excel today?",
        },
        {
          onSuccess: async ({ newContent, conversationId }) => {
            setChatHistory((prev) => [...prev, { id: Date.now(), content: newContent, role: "AI", conversationId }]);
            setIsStreaming(false);
            setSelectedConversationId(conversationId);

            // Save the selected conversation ID in Office context
            Office.context.document.settings.set("lastConversationId", conversationId);
            Office.context.document.settings.saveAsync();
          },
          onError: () => {
            setIsStreaming(false);
            toast.error("Failed to generate conversation.");
          },
          onSettled: () => {
            setIsLoadingResponse(false);
          },
        }
      );
    } else {
      // Send the message using the useSendMessage hook
      sendMessageMutate(
        {
          message: messageToSend,
          conversation_id: selectedConversationId!,
          model: LLM_MODELS.DEFAULT,
        },
        {
          onSuccess: async (newContent) => {
            // Remove failed message if there is any
            setChatHistory((prev) => prev.filter((msg) => !msg.failed));

            setChatHistory((prev) => [
              ...prev,
              { id: Date.now(), content: newContent, role: "AI", conversationId: selectedConversationId! },
            ]);
          },
          onError: () => {
            // Mark the message as failed and show the error
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === userMessageId
                  ? {
                      ...msg,
                      failed: true, // Mark as failed
                    }
                  : msg
              )
            );
            toast.error("Failed to send message.");
          },
          onSettled: () => {
            setIsLoadingResponse(false);
          },
        }
      );
    }
  };

  // Create a ref for the chat container
  const chatContainerRef = React.useRef(null);

  // Auto-scroll to the bottom whenever chatHistory updates
  React.useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, lastMessage]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey && !isLoadingResponse && !isStreaming) {
      event.preventDefault();
      handleSendMessage();
    }
  };
  const handleDeleteClick = (conversationId: number) => {
    setConversationToDelete(conversationId);
    setIsCreatingNewConversation(false);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteConversation(
      { conversationId: conversationToDelete },
      {
        onSuccess: () => {
          if (selectedConversationId === conversationToDelete) {
            setSelectedConversationId(null);
            setChatHistory([]); // Clear chat history only if it's the same conversation
            Office.context.document.settings.remove("lastConversationId");
            Office.context.document.settings.saveAsync();
          }
        },
      }
    );
    setIsDeleteDialogOpen(false);
    setIsDrawerOpen(false);
  };

  const handleConversationSelect = (conversationId: number) => {
    setSelectedConversationId(conversationId);
    setIsCreatingNewConversation(false);
    setIsDrawerOpen(false);
    if (selectedConversationId !== conversationId) setChatHistory([]);

    // Save the selected conversation ID in Office context
    Office.context.document.settings.set("lastConversationId", conversationId);
    Office.context.document.settings.saveAsync();
  };

  const handleNewConversation = () => {
    setIsCreatingNewConversation(true);
    // Remove lastConversationId since it's a new conversation
    Office.context.document.settings.remove("lastConversationId");
    Office.context.document.settings.saveAsync();
    setSelectedConversationId(null);
    setIsDrawerOpen(false);
    setChatHistory([
      {
        id: Date.now(),
        content: "Hello! How can I assist you with Excel today?",
        role: "AI",
        conversationId: 0, // No conversationId yet
      },
    ]);
  };

  return (
    <MainLayout headerTitle="AI Chat">
      <GlassBackground>
        <div
          className="h-[60vh] overflow-y-auto bg-transparent rounded-lg flex flex-col gap-2.5"
          ref={chatContainerRef}
        >
          <style>
            {"div::-webkit-scrollbar { display: none; }" /* Hides scrollbar for Chrome, Safari, and Edge */}
          </style>
          {messagesError && (
            <p className="text-center my-5 flex items-center justify-center h-full" style={{ color: theme.error }}>
              Failed to fetch messages
            </p>
          )}

          {isLoadingConversations ||
            (isLoadingMessages && (
              <div className="relative h-full w-full">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <LoadingCircle width="20px" height="20px" />
                </div>
              </div>
            ))}

          {chatHistory.length > 0 ? (
            chatHistory.map((message) => <MemoizedRenderChatBubble key={message.id} message={message} />)
          ) : (
            <div style={{ flex: 1 }}> {/* Empty space filler when no messages */} </div>
          )}

          {isStreaming && lastMessage && (
            <MemoizedRenderChatBubble
              key={chatHistory.length}
              message={{ id: Date.now(), content: lastMessage, role: "AI", conversationId: selectedConversationId! }}
            />
          )}
        </div>
        <div className="flex items-center gap-2.5 mt-auto">
          <div className="flex-1 flex items-center relative">
            <button
              className="absolute left-[5px] bg-transparent cursor-pointer top-1/2 -translate-y-1/2"
              onClick={() => setIsDrawerOpen(true)}
              disabled={isLoadingConversations || isLoadingMessages}
            >
              <div className="flex items-center justify-center rounded-md bg-[rgba(8,8,8,1)] p-1.5">
                <BiConversation fill="#fff" />
              </div>
            </button>

            <textarea
              ref={textareaRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Ask AI anything..."
              className="inputField resize-none w-full px-10 py-2.5 rounded-lg border border-[rgba(217,217,217,0.4)] bg-[rgba(217,217,217,0.3)] text-white outline-none overflow-y-auto max-h-[150px]"
              style={{ fontFamily: theme.fontFamily }}
              rows={1}
              onInput={(e) => {
                e.currentTarget.style.height = "auto";
                if (e.currentTarget.scrollHeight > e.currentTarget.clientHeight) {
                  e.currentTarget.style.height = `${e.currentTarget.scrollHeight - 15}px`;
                }
              }}
            />

            <button
              className="absolute right-[10px] border-none bg-transparent cursor-pointer top-1/2 -translate-y-1/2"
              onClick={handleSendMessage}
              disabled={isLoadingConversations || isLoadingMessages || isDeletingConversation || isLoadingResponse}
            >
              {isLoadingResponse ? <LoadingIndicator /> : <Image src={SendIcon} alt="Send" />}
            </button>
          </div>
        </div>
      </GlassBackground>

      <Drawer
        open={isDrawerOpen}
        onOpenChange={(event, data) => setIsDrawerOpen(data.open)}
        size="small"
        style={{ background: "#2fc5bb" }}
        separator={true}
      >
        {/* React fluent ui components: Only inline style works, can not override when using tailwindcss */}
        <DrawerHeader
          style={{
            padding: "16px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div className="flex items-center justify-end">
            <button
              className="border-none bg-[rgba(255,255,255,0.2)] cursor-pointer p-[3px] rounded-lg transition ease-in-out duration-300 flex items-center justify-center"
              onClick={() => setIsDrawerOpen(false)}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.4)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.2)";
              }}
            >
              <MdClose size={16} color={"#fff"} />
            </button>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-white m-0 float-left font-bold" style={{ fontSize: theme.fontSize.large }}>
              Available Conversations
            </h2>
            <button
              className="border-none cursor-pointer bg-transparent p-[3px] rounded-lg transition ease-in-out duration-300 flex items-center justify-center"
              onClick={handleNewConversation}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.4)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "transparent";
              }}
            >
              <IoCreateOutline size={20} color={"#fff"} />
            </button>
          </div>
        </DrawerHeader>

        <DrawerBody
          style={{
            padding: "16px",
          }}
        >
          {isLoadingConversations || isFetchingConversations ? (
            <div className="flex justify-center">
              <LoadingCircle width="20px" height="20px" />
            </div>
          ) : conversations && conversations.length > 0 ? (
            conversations.map((conversation) => (
              <div
                key={conversation.id}
                className="p-3 mb-2 border border-[rgba(204,204,204,0.4)] rounded-lg flex justify-between items-center cursor-pointer transition-all duration-300 text-white bg-[rgba(255,255,255,0.1)]"
                onMouseEnter={(e) => {
                  e.currentTarget.style.background = "rgba(255, 255, 255, 0.3)";
                  e.currentTarget.style.transform = "scale(1.02)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.background = "rgba(255, 255, 255, 0.1)";
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <span
                  onClick={() => handleConversationSelect(conversation.id)}
                  className="flex-1"
                  style={{
                    fontSize: theme.fontSize.medium,
                  }}
                >
                  {conversation.title}
                </span>
                <Dialog open={isDeleteDialogOpen} onOpenChange={(event, data) => setIsDeleteDialogOpen(data.open)}>
                  <DialogTrigger disableButtonEnhancement>
                    <button
                      className="border-none bg-transparent cursor-pointer p-1 ml-2 flex items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(conversation.id);
                      }}
                    >
                      <MdDelete size={18} color={theme.error} />
                    </button>
                  </DialogTrigger>
                  <CustomDialogSurface
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onAction={handleConfirmDelete}
                    variant="confirmation"
                    content="Are you sure you want to delete this conversation?"
                  />
                </Dialog>
              </div>
            ))
          ) : (
            <p
              className="text-white"
              style={{
                fontSize: theme.fontSize.small,
              }}
            >
              No conversations available
            </p>
          )}
        </DrawerBody>
      </Drawer>
    </MainLayout>
  );
};
