import React from "react";

export const GlassBackground = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="box-border flex flex-col flex-1 bg-[rgba(217,217,217,0.4)] p-2.5 rounded-lg overflow-hidden h-full relative">
      <div className="flex items-center justify-center w-full absolute top-[5px] z-[5]">
        <div className="bg-[rgba(255,255,255,0.6)] rounded-[20px] h-[2px] w-[30px]"></div>
      </div>
      {children}
    </div>
  );
};
