/* global console */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiDelete } from "@/utils/fetch";
import { CHAT_AI_ENDPOINT } from "@/constants";
import toast from "react-hot-toast";

interface DeleteConversationResponse {
  message: string;
}

interface DeleteConversationInput {
  conversationId: number;
}

interface DeleteConversationContext {
  previousConversations?: DeleteConversationInput[];
}

interface Conversations {
  id: number;
}
export const useDeleteConversation = () => {
  const queryClient = useQueryClient();

  return useMutation<DeleteConversationResponse, Error, DeleteConversationInput, DeleteConversationContext>({
    mutationFn: ({ conversationId }) => apiDelete(`${CHAT_AI_ENDPOINT}/${conversationId}`),

    onMutate: async ({ conversationId }) => {
      await queryClient.cancelQueries({ queryKey: ["conversations"] });

      const previousConversations = queryClient.getQueryData<DeleteConversationInput[]>(["conversations"]);

      queryClient.setQueryData<Conversations[]>(["conversations"], (old) => {
        if (!old) return [];
        return old.filter((conversation: Conversations) => conversation.id !== conversationId);
      });

      return { previousConversations };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
      toast.success("Conversation deleted.");
    },

    onError: (err, { conversationId }, context) => {
      if (context?.previousConversations) {
        queryClient.setQueryData(["conversations"], context.previousConversations);
      }
      toast.error(`Failed to delete conversation with id of ${conversationId}`);
      console.error(`Failed to delete conversation with id ${conversationId}:`, err);
    },
  });
};
