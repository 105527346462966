import React from "react";
import { Divider, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from "@fluentui/react-components";
import { MdClose } from "react-icons/md";
import { PiFilesFill, PiLockKeyOpenFill } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";
import { useLogout } from "@/hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { USER_CHANGE_PASSWORD_ROUTE, VIEW_PLANS_ROUTE } from "@/constants";
import { useGetUserInfo } from "@/hooks/useGetUserInfo";

interface UserDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const UserDrawer: React.FC<UserDrawerProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { data: userInfo, isPending } = useGetUserInfo();
  const { mutate: logout } = useLogout();

  const handleChangePassword = () => {
    navigate(USER_CHANGE_PASSWORD_ROUTE);
  };

  const handleViewPlans = () => {
    navigate(VIEW_PLANS_ROUTE);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <Drawer
      separator
      open={isOpen}
      onOpenChange={(_, { open }) => {
        if (!open) onClose();
      }}
      style={{
        maxWidth: 200,
        background: "linear-gradient(to bottom, #1ddbd4, #0e8ed8)",
        boxShadow: "2px 0 10px rgba(0, 0, 0, 0.2)",
        color: "white",
      }}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          className="p-2"
          action={
            <button
              className="border-none bg-[rgba(255,255,255,0.2)] cursor-pointer p-[3px] rounded-lg transition ease-in-out duration-300 flex items-center justify-center"
              onClick={onClose}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.4)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.2)";
              }}
            >
              <MdClose size={16} color={"#fff"} />
            </button>
          }
        >
          <div className="flex items-center gap-2">
            <div
              className="flex items-center justify-center size-12 rounded-full text-white font-bold text-lg"
              style={{ backgroundColor: "rgba(31, 9, 84, 0.3)" }}
            >
              {userInfo ? userInfo.email.slice(0, 2).toUpperCase() : "SS"}
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Member</span>
              <span className="text-[10px]">{userInfo.email}</span>
            </div>
          </div>
        </DrawerHeaderTitle>
        <Divider className="w-full" appearance="subtle" style={{ opacity: 0.3 }} />
      </DrawerHeader>

      <DrawerBody style={{ padding: "8px", height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
        <button
          onClick={handleViewPlans}
          className="hover:bg-[#06e3d3] transition-colors rounded-md flex items-center gap-1 p-2 w-full"
        >
          <PiFilesFill className="size-4" />
          <span className="font-bold text-sm">View Plans</span>
        </button>
        <button
          onClick={handleChangePassword}
          className="hover:bg-[#06e3d3] transition-colors rounded-md flex items-center gap-1 p-2 w-full"
        >
          <PiLockKeyOpenFill className="size-4" />
          <span className="font-bold text-sm">Change Password</span>
        </button>

        {/* Spacer to push the logout section to the bottom */}
        <div className="flex-grow" />

        {/* Bottom Section */}
        <div className="mt-2">
          <div className="w-full h-px bg-gray-400 opacity-30 my-2" /> {/* Divider */}
          <button
            onClick={() => logout()}
            className="w-full text-left bg-red-500 hover:bg-red-700 rounded-md py-1.5 px-1 transition-colors flex items-center justify-center"
          >
            <FiLogOut className="size-3 mr-2" />
            <span className="text-xs">Logout</span>
          </button>
        </div>
      </DrawerBody>
    </Drawer>
  );
};

export default UserDrawer;
