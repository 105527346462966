/* global Excel */
import { Button } from "@fluentui/react-components";
import React, { useState } from "react";
import { PiDownloadSimpleThin } from "react-icons/pi";
import { toast } from "react-hot-toast";
import { getCurrentWorkbookAsBase64, isDesktopExcel } from "@/taskpane/taskpane";
import { createNewWorkbookFromCurrent } from "@/utils/excelFromSources";

const Step4Finished = ({
  addedSheetNames,
  originalSheetsName,
  fileName,
  onGoAgain,
}: {
  addedSheetNames: string[];
  originalSheetsName: string[];
  selectedSheetName: string;
  fileName: string;
  processedFileUrl: string;
  onGoAgain: () => void;
}) => {
  const [canDownload, setCanDownload] = useState(true);
  console.log(addedSheetNames, originalSheetsName);
  const downloadFile = async () => {
    try {
      await Excel.run(async (context) => {
        const workbook = context.workbook;

        // Load all worksheets in the workbook
        const worksheets = workbook.worksheets;
        worksheets.load("items/name, items/visibility");
        await context.sync();

        if (addedSheetNames[0].includes("sheetshark-csv")) {
          const addedSheet = workbook.worksheets.getItem(addedSheetNames[0]);
          addedSheet.visibility = Excel.SheetVisibility.visible;
          await context.sync();
        }

        // Step 1: Modify visibility for download
        worksheets.items.forEach((sheet) => {
          if (originalSheetsName.includes(sheet.name)) {
            // Hide original sheets (except _temp_chart_data)
            if (!/^_temp_chart_data( \(\d+\))?$/.test(sheet.name)) {
              const originalSheet = workbook.worksheets.getItem(sheet.name);
              originalSheet.visibility = Excel.SheetVisibility.hidden;
            }
          } else if (addedSheetNames.includes(sheet.name)) {
            // Reveal added sheets
            console.log("added sheet name: ", addedSheetNames);
            const addedSheet = workbook.worksheets.getItem(sheet.name);

            addedSheet.visibility = Excel.SheetVisibility.visible;
          }
        });
        await context.sync();

        console.log("Original sheets hidden and added sheets revealed successfully.");
      });

      // Step 2: Generate and download the file
      const base64String = await getCurrentWorkbookAsBase64();

      // Convert Base64 to a Blob
      const byteCharacters = atob(base64String); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a downloadable URL
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `sheetshark-${fileName}.xlsx`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Cleanup the Object URL after download
      URL.revokeObjectURL(url);
      toast.success("File downloaded successfully");
      console.log("Download triggered successfully.");
    } catch (err) {
      toast.error("There was an error when trying to download");
      console.error("Error during download:", err);
    } finally {
      try {
        await Excel.run(async (context) => {
          const workbook = context.workbook;

          // Load all worksheets in the workbook
          const worksheets = workbook.worksheets;
          worksheets.load("items/name, items/visibility");
          await context.sync();

          // Step 3: Reveal original sheets first
          worksheets.items.forEach((sheet) => {
            if (originalSheetsName.includes(sheet.name)) {
              if (!/^_temp_chart_data( \(\d+\))?$/.test(sheet.name)) {
                const originalSheet = workbook.worksheets.getItem(sheet.name);
                originalSheet.visibility = Excel.SheetVisibility.visible;
              }
            }
          });
          await context.sync();
          console.log("Original sheets restored successfully.");

          // Step 4: Delete added sheets
          addedSheetNames.forEach((sheetName) => {
            if (!/^_temp_chart_data( \(\d+\))?$/.test(sheetName)) {
              const addedSheet = workbook.worksheets.getItem(sheetName);
              addedSheet.delete();
            }
          });
          await context.sync();
          console.log("Added sheets deleted successfully.");

          setCanDownload(false);
        });
      } catch (cleanupError) {
        console.error("Error during cleanup or restoring sheets:", cleanupError);
      }
    }
  };

  const openInExcel = async () => {
    if (isDesktopExcel()) {
      await createNewWorkbookFromCurrent(addedSheetNames, originalSheetsName);
    } else {
      //just reveal the hidden worksheets in the current workbook
    }
  };

  return (
    <div className="h-full w-full my-auto px-2">
      <div className="text-center">
        <div className="text-5xl mb-4">🎉</div>
        <h2 className="text-2xl font-bold mb-4">Success!</h2>
        <p className="mb-6 text-xs">Your file has been successfully processed.</p>

        {/* Download Button */}
        {!isDesktopExcel() && (
          <>
            {canDownload ? (
              <Button
                size="large"
                style={{
                  background: "linear-gradient(to right, #38ccd5 0%, #36c7d9 80%)",
                  width: "100%",
                  padding: "0.5rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
                  marginBottom: "1rem",
                  color: "#fff", // Text color for the active button
                }}
                onClick={downloadFile}
              >
                <PiDownloadSimpleThin strokeWidth="5px" className="mr-2 size-5" />
                Download
              </Button>
            ) : (
              <Button
                size="large"
                style={{
                  background: "linear-gradient(to right, rgb(15,110,197,0.8) 0%, rgb(15,110,197,0.8) 80%)", // "Clicked" button background
                  width: "100%",
                  padding: "0.5rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.3)", // Subtle shadow for "clicked" button
                  marginBottom: "1rem",
                  color: "#fff", // Text color for the disabled button
                }}
                disabled
              >
                <PiDownloadSimpleThin strokeWidth="5px" className="mr-2 size-5" />
                Downloaded
              </Button>
            )}
          </>
        )}

        {/* Copy to Excel Button */}
        {isDesktopExcel() && (
          <Button
            size="large"
            appearance="primary"
            style={{
              background: "linear-gradient(to right, #4caf50, #81c784)",
              width: "100%",
              padding: "0.5rem",
              borderRadius: "8px",
              fontSize: "12px",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
              marginBottom: "1rem",
            }}
            onClick={openInExcel}
          >
            Open in Excel
          </Button>
        )}

        {/* Go Again Button */}
        <Button
          size="large"
          appearance="secondary"
          style={{
            background: "#f3f4f6",
            color: "#333",
            width: "100%",
            padding: "0.5rem",
            borderRadius: "8px",
            fontSize: "12px",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
          }}
          onClick={onGoAgain}
        >
          Go Again
        </Button>
      </div>
    </div>
  );
};

export default Step4Finished;
