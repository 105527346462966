export type ChartType = "bar" | "cluster" | "trend";

export type ExcelChartType =
  | "BarStacked"
  | "BarClustered"
  | "ColumnClustered"
  | "ColumnStacked"
  | "Line"
  | "Pie"
  | "Area"
  | "Doughnut";

export type ExcelChartTypeAPI =
  | "BarStacked"
  | "BarClustered"
  | "ColumnClustered"
  | "ColumnStacked"
  | "Line"
  | "Pie"
  | "Area"
  | "Doughnut"
  | "Invalid";

export enum RangeStatus {
  NoValues = "No values available",
  ValuesAvailable = "Values available",
  Error = "Error checking values",
}
