import { Button, Image } from "@fluentui/react-components";
import React, { useEffect, useState, useCallback } from "react";
import LoadingCircle from "./LoadingCircle";

import SheetShark from "@/assets/sheetshark_logo_main_stacked_white.svg";
import { useTheme } from "@/providers/ThemeProvider";
import { useResendVerificationEmail } from "@/hooks/useResendVerificationEmail";
import { useEmailVerification } from "@/hooks/useEmailVerification";
import { useLogout } from "@/hooks/useLogout";
import { FiLogOut } from "react-icons/fi";

export const EmailVerification = () => {
  const { theme } = useTheme();
  const [inputToken, setInputToken] = useState<string>("");
  const [cooldown, setCooldown] = useState<number>(0);

  const { mutate: resendVerificationEmail } = useResendVerificationEmail();
  const { mutate: verifyEmail, isPending: isVerifyingEmail } = useEmailVerification();
  const { mutate: logout, isPending: isLoggingOut } = useLogout();

  useEffect(() => {
    let timer: number;
    if (cooldown > 0) {
      timer = window.setTimeout(() => setCooldown((prev) => prev - 1), 1000);
    }
    return () => window.clearTimeout(timer);
  }, [cooldown]);

  const handleResend = useCallback(() => {
    resendVerificationEmail();
    setCooldown(59); // Start cooldown timer for 59 seconds
  }, [resendVerificationEmail]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      verifyEmail({ token: inputToken });
    },
    [verifyEmail, inputToken]
  );

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full gap-4 max-w-sm px-4">
        <div className="flex flex-col items-center">
          <Image src={SheetShark} alt="SheetShark Logo" height={140} width={140} />
          <h2 style={{ color: theme.textColor }} className="text-sm">
            Email Verification
          </h2>
        </div>
        <input
          onChange={(e) => setInputToken(e.target.value)}
          disabled={isVerifyingEmail}
          className="w-full px-5 py-2 rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none placeholder-white"
          placeholder="Enter verification code"
          type="text"
        />
        <Button
          appearance="primary"
          disabled={isVerifyingEmail || inputToken.trim() === ""}
          size="large"
          type="submit"
          style={{
            width: "100%",
            color: "white",
            padding: "0.6rem 1rem",
            borderRadius: "8px",
            background: "#080808",
            fontSize: "12px",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isVerifyingEmail ? <LoadingCircle width="16px" height="16px" /> : "Verify Email"}
        </Button>
        <Button onClick={handleResend} disabled={cooldown > 0}>
          <span
            className={`text-sm text-white cursor-pointer ${
              cooldown > 0 ? "hover:no-underline cursor-not-allowed" : "hover:underline"
            }`}
          >
            {cooldown > 0 ? `Resend in ${cooldown}s` : "Resend Verification Email"}
          </span>
        </Button>
        <button
          type="button"
          className="text-white/90 hover:text-white hover:bg-white/10 transition-colors group flex items-center gap-2 px-6 py-2 rounded-full text-sm"
          onClick={handleLogout}
        >
          {isLoggingOut ? (
            <LoadingCircle width="12px" height="12px" />
          ) : (
            <>
              <span className="text-xs">Logout</span>
              <FiLogOut className="h-3 w-3 transition-transform group-hover:translate-x-0.5" />
            </>
          )}
        </button>
      </form>
    </div>
  );
};
