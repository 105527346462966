// Define your light and dark themes, or any other themes you want
export const themes = {
  light: {
    background: "#ffffff",
    secondary: "#ffffff,",
    textColor: "#000000",
    tierButton: "#00002D",
    iconColor: "#000000",
    fontSize: {
      small: "10px",
      medium: "14px",
      large: "16px",
    },
    error: "#FF4500",
    fontFamily: "Switzer, sans-serif",
  },
  dark: {
    background: "#1f09544d",
    secondary: "#ffffff,",
    textColor: "#ffffff",
    secondaryText: "#EDEDED",
    tierButton: "#00002D",
    iconColor: "#31DD8F",
    fontSize: {
      small: "10px",
      medium: "14px",
      large: "16px",
    },
    error: "#FF4500",
    fontFamily: "Switzer, sans-serif",
  },
};

// Export the type for easier access
export type ThemeType = keyof typeof themes;
