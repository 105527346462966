export const plans = [
  {
    name: "Basic",
    price: 0,
    description: "Great for testing and occasional use",
    features: [
      { title: "50 Formula Generations", sub: "Per month with access to AI assistance" },
      { title: "Limited Chat Support", sub: "Ask questions or get help with formulas" },
      { title: "Community Access", sub: "Join and share with other Excel users" },
      { title: "Excel Plugin Integration", sub: "Use directly inside your spreadsheet" },
    ],
    highlight: false,
    badge: "FREE",
    current: true,
  },
  {
    name: "Pro",
    price: 19,
    description: "Unlimited access for power users",
    features: [
      { title: "Unlimited Formula Generations", sub: "No monthly cap on AI assistance" },
      { title: "Unlimited Chat Messages", sub: "Full access to formula-related chat support" },
      { title: "Graphing & Visual Insights", sub: "Generate charts and visual summaries" },
      { title: "Faster Response Times", sub: "Get priority access and reduced wait times" },
    ],
    highlight: true,
    badge: "POPULAR",
  },
  {
    name: "Premium",
    price: 39,
    description: "Advanced features for business workflows",
    features: [
      { title: "Everything in Pro", sub: "All unlimited features included" },
      { title: "Excel Sheet Generation", sub: "Let AI create full spreadsheets from prompts" },
      { title: "Real-time Collaboration", sub: "Share sessions and formulas with your team" },
      { title: "Custom Branding", sub: "White-label for internal tools or clients" },
      { title: "Developer API Access", sub: "Integrate AI into your workflows programmatically" },
    ],
    highlight: false,
    badge: null,
  },
];
