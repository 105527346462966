import { Button } from "@fluentui/react-components";
import React, { useState } from "react";
import LoadingCircle from "./LoadingCircle";
import toast from "react-hot-toast";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";

import { useTheme } from "@/providers/ThemeProvider";
import { useChangePassword } from "@/hooks/useChangePassword";
import { MainLayout } from "@/taskpane/layouts/MainLayout";

export const UserChangePasswordView = () => {
  const { theme } = useTheme();
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedNewPassword, setRepeatedNewPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  // Password visibility states
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { mutate: changePassword, isPending } = useChangePassword();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError(false);

    if (newPassword !== repeatedNewPassword) {
      setPasswordError(true);
      toast.error("New passwords do not match!");
      return;
    }

    changePassword(
      { password, newPassword, repeatedNewPassword },
      {
        onSuccess: (data) => {
          if (data.ok) {
            setPassword("");
            setNewPassword("");
            setRepeatedNewPassword("");
            toast.success("Password changed successfully!");
          } else {
            toast.error(data.message || "Password change failed.");
          }
        },
        onError: (error) => {
          toast.error(error.message || "An unexpected error occurred.");
        },
      }
    );
  };

  return (
    <MainLayout headerTitle="Change Password">
      <div className="flex w-full items-center justify-center">
        <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full gap-4 max-w-sm px-4">
          <div className="flex flex-col items-center">
            <h2 style={{ color: theme.textColor }} className="text-sm">
              Enter your current and new password
            </h2>
          </div>

          {/* Current Password */}
          <div className="flex flex-col gap-1 w-full relative">
            <label className="text-white" style={{ fontSize: theme.fontSize.medium }}>
              Current Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              disabled={isPending}
              className="w-full px-5 py-2 text-sm rounded-lg border border-gray-300/40 bg-gray-300/30 text-white outline-none placeholder-white pr-10"
              placeholder="Enter current password"
              type={showPassword ? "text" : "password"}
              value={password}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-3 top-9 hover:text-white transition-colors"
            >
              {showPassword ? <PiEyeSlash size={18} /> : <PiEyeLight size={18} />}
            </button>
          </div>

          {/* New Password */}
          <div className="flex flex-col gap-1 w-full relative">
            <label className="text-white" style={{ fontSize: theme.fontSize.medium }}>
              New Password
            </label>
            <input
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={isPending}
              className={`w-full px-5 py-2 rounded-lg border text-sm pr-10 ${
                passwordError ? "border-red-500" : "border-gray-300/40"
              } bg-gray-300/30 text-white outline-none placeholder-white`}
              placeholder="Enter new password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              required
            />
            <button
              type="button"
              onClick={() => setShowNewPassword((prev) => !prev)}
              className="absolute right-3 top-9 hover:text-white transition-colors"
            >
              {showNewPassword ? <PiEyeSlash size={18} /> : <PiEyeLight size={18} />}
            </button>
          </div>

          {/* Confirm Password */}
          <div className="flex flex-col gap-1 w-full relative">
            <label className="text-white" style={{ fontSize: theme.fontSize.medium }}>
              Confirm Password
            </label>
            <input
              onChange={(e) => setRepeatedNewPassword(e.target.value)}
              disabled={isPending}
              className={`w-full px-5 py-2 rounded-lg border text-sm pr-10 ${
                passwordError ? "border-red-500" : "border-gray-300/40"
              } bg-gray-300/30 text-white outline-none placeholder-white`}
              placeholder="Confirm new password"
              type={showConfirmPassword ? "text" : "password"}
              value={repeatedNewPassword}
              required
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              className="absolute right-3 top-9 hover:text-white transition-colors"
            >
              {showConfirmPassword ? <PiEyeSlash size={18} /> : <PiEyeLight size={18} />}
            </button>
          </div>

          <Button
            appearance="primary"
            disabled={isPending}
            size="large"
            type="submit"
            style={{
              width: "100%",
              color: "white",
              padding: "0.6rem 1rem",
              borderRadius: "8px",
              background: "#080808",
              fontSize: "12px",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isPending ? <LoadingCircle width="16px" height="16px" /> : "Change Password"}
          </Button>
        </form>
      </div>
    </MainLayout>
  );
};
