import { useQuery } from "@tanstack/react-query";
import { apiGet } from "@/utils/fetch";

export interface Message {
  id: number;
  content: string;
  role: string; // HUMAN or AI, SYSTEM
  conversationId: number;
}

export const useFetchConversationMessages = (conversationId: number) =>
  useQuery<Message[], Error>({
    queryKey: ["conversationMessages", conversationId],
    queryFn: async () => {
      const response = await apiGet<Message[]>(`/api/conversations/${conversationId}`);

      return response as Message[];
    },
    enabled: !!conversationId, // Only run if conversationId is valid
  });
